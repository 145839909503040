import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import _ from 'lodash';

import { listUsers } from '../redux/slices/users';

const UserSelection = ({ handleOnUserChange, selectedUser, input, label }) => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.users);

  useEffect(() => {
    dispatch(listUsers())
  }, [])

  const renderUser = (users, defaultValue, onChange) => {
    const options = _.map(users, user => {
      return {
        key: user.id,
        value: user.email,
        name: user.name || user.email,
      }
    })

    return (
      <>
        {
          input ?
            <select className="form-control" {...input} value={input.value} onChange={(e) => {
              input.onChange(e);
              if(onChange){
                onChange(e);
              }
            }}>
              {
                options.map(option => {
                  return (
                    <option key={option.key} value={option.value}>{option.name} - {option.value}</option>
                  )
                })
              }
            </select>
            :
            <select className="form-control" onChange={onChange} value={defaultValue}>
              {
                options.map(option => {
                  return (
                    <option key={option.key} value={option.value}>{option.name} - {option.value}</option>
                  )
                })
              }
            </select>
        }
      </>
    )
  }

  return (
    <div className="user-selection">
      <label style={{marginBottom: '5px'}}>{label || 'User'}</label>
      {renderUser(items, selectedUser, handleOnUserChange)}
    </div>
  )
}

export default UserSelection;
