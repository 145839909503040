import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  item: null,
}

export const getUserDetail = createAsyncThunk('user/detail', async (id, thunkAPI) => {
  try {
    return await api(`/users/${id}`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const getProfile = createAsyncThunk('user/profile', async (_, thunkAPI) => {
  try {
    return await api(`/user/profile`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.loaded = false;
      state.item = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(getUserDetail.pending , getProfile.pending), (state) => {
        state.loading = true;
      })
      .addMatcher(isAnyOf(getUserDetail.fulfilled, getProfile.fulfilled), (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.item = action.payload.user
        state.lastUpdated = action.payload.receivedAt
      })
      .addMatcher(isAnyOf(getUserDetail.rejected, getProfile.rejected), (state, action) => {
        state.loading = false;
      })
  }
})

export const { clearUser } = userSlice.actions

export default userSlice.reducer
