import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Card, Button, Badge, ButtonToolbar, Row, Col, Anchor } from 'react-bootstrap';
import LineItems from '../components/LineItems';
import Fulfillment from '../components/Fulfillment';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import _ from 'lodash'
import ManualOrderLineItems from '../components/ManualOrderLineItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import Clipboard from 'clipboard';

import { fetchManualOrderDetail } from '../redux/slices/manualOrderDetail';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'

const ManualOrderDetail = () => {
  const { item, loading, loaded } = useSelector(state => state.manualOrderDetail);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  useEffect(() => {
    dispatch(fetchManualOrderDetail(orderId))

    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {

      e.trigger.innerText = 'COPIED'
      e.clearSelection();
    });

  }, [])

  return (
    <>
      {
        loading &&
          <Loader />
      }
      {
        !loading && item &&
          <>
            <div className="row mt-3 mb-4">
              <div className="col-xs-12 col-md-9">
                <h2 className="sub-header">Order Detail</h2>
              </div>
            </div>
            <div className='d-grid gap-4'>
              <div className="d-none d-print-block">⚠️ KINDLY DO NOT PASS THIS ORDER SLIP TO RECEIVER</div>
              <Row>
                <Col sm={6} md={5} lg={4}>
                  <Card className="order-detail-card shadow-sm p-2">
                    <Card.Body>
                      <div>
                        <h4 className="mb-0 d-inline-block" style={{color: '#80d7cf'}}>{item.order_id}</h4>
                      </div>
                      <p className="d-print-none my-3">placed on {moment(item.created).format('YYYY-MM-DD HH:mm:ss')}</p>
                      {
                        item.cancelled_at &&
                          <Badge bg="danger" style={{lineHeight: '1.5em'}}>CANCELLED</Badge>
                      }
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
                <Col>
                  <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                    <Card.Header className="tealCardHeader">
                      Order Info
                    </Card.Header>
                    <Card.Body className="grey-label">
                      <Row className="flex-row">
                        <Col lg={4} className="m-2">
                          <Row className="flex-lg-column w-100">
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Financial Status</label>
                              <span className="text-uppercase">{item.financial_status.replace("_", " ")}</span>
                            </Col>
                            {
                              item.financial_status === 'partially_paid' &&
                                <>
                                  <Col xs={6} lg={12} className="d-grid mb-2">
                                    <label>Partial Paid Amount</label>
                                    <span>{currency} {item.partial_paid_amount}</span>
                                  </Col>
                                  <Col xs={6} lg={12} className="d-grid mb-2">
                                    <label>Due Date</label>
                                    <span>{moment(item.payment_due_date).format("YYYY-MM-DD")}</span>
                                  </Col>
                                </>
                            }
                            <Col  xs={6} lg={12} className="d-grid mb-2">
                              <label>Fulfillment Status</label>
                              {
                                item.fulfillment_status ?
                                  <span className="text-uppercase" style={{color: '#4e8767'}}>{item.fulfillment_status}</span>
                                  :
                                  <span className="text-uppercase" style={{color: '#ffc107'}}>Unfulfilled</span>
                              }
                            </Col>
                            {
                              item.cancelled_at &&
                                <Col xs={6} lg={12} className="d-grid mb-2">
                                  <label>Order Status</label>
                                  <span  className="text-uppercase" style={{color: '#d15d5d'}}>Cancelled</span>
                                </Col>
                            }
                          </Row>
                        </Col>
                        <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
                        <Col className="m-2 mt-2 my-lg-auto label-strong">
                          <Row>
                            <Col className="text-end"><label>Total Item Price</label></Col>
                            <Col>{currency} {(item.item_price + item.markup_total).toFixed(2)}</Col>

                          </Row>
                          <Row>
                            <Col className='text-end'><label>Shipping Subtotal</label></Col>
                            <Col>{currency} {item.shipping_fee.toFixed(2)}</Col>
                          </Row>
                          <Row>
                            <Col className='text-end'><label>Total</label></Col>
                            <Col>{currency} {(item.item_price + item.shipping_fee + item.markup_total).toFixed(2)}</Col>
                          </Row>
                          {
                            item.financial_status === 'partially_paid' &&
                              <Row>
                                <Col className='text-end'><label>Total Due</label></Col>
                                <Col>{currency} {(item.item_price + item.shipping_fee - item.partial_paid_amount).toFixed(2)}</Col>
                              </Row>
                          }
                          <hr style={{margin: '5px', color:'#707070'}} />
                          {
                            item.order_invoice &&
                              <Row>
                                <Col className='text-end'><label>Order Invoice</label></Col>
                                <Col>
                                  <a href={item.order_invoice} target="_blank" style={{wordBreak: 'break-word'}}>{item.order_invoice}</a>
                                </Col>
                              </Row>
                          }
                          {
                            item.sales_receipt && item.sales_receipt.length > 0 &&
                              <Row>
                                <Col className='text-end'><label>Sales Receipt</label></Col>
                                <Col>
                                  {
                                    _.map(item.sales_receipt, (receipt, i) => {
                                      return (
                                        <div key={i} className="d-grid">
                                          <span>{receipt.filename}</span>
                                          <Anchor target="_blank" href={receipt.link} variant="primary" style={{wordBreak: 'break-all'}}>{receipt.link}</Anchor>
                                        </div>
                                      )
                                    })
                                  }
                                </Col>
                              </Row>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                    <Card.Body className="mx-2">
                      <Row className="overflowX-auto">
                        <Col lg={2} className="d-print-none grey-label">
                          <div className="d-grid mb-2">
                            <label>Customer Name</label>
                            <span>{item.first_name} {item.last_name}</span>
                          </div>
                          <div className="d-grid mb-2">
                            <label>Email</label>
                            <span style={{wordBreak: 'break-all'}}>{item.email}</span>
                          </div>
                        </Col>
                        {
                          (item.note || (item.note_attributes && item.note_attributes.length)) || item.delivery_address ?
                            <Col lg={6} className="mt-2 mt-lg-0">
                              <Card className="greyCard">
                                <Card.Header className="greyCardHeader">
                                  Delivery Info
                                </Card.Header>
                                <Card.Body className="label-strong d-grid gap-2 overflowX-auto">
                                  {
                                    item.remarks &&
                                      <Row>
                                        <label className="text-uppercase">Internal Remarks</label>
                                        <span className="delivery-info-value" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> {item.remarks}</span>
                                      </Row>
                                  }
                                  {
                                    item.delivery_address &&
                                      <>
                                        <Row>
                                          <label>Sender's Phone Number</label>
                                          <span className="delivery-info-value">{item.sender_phone_number}</span>
                                        </Row>
                                        <Row>
                                          <label>Receiver</label>
                                          <span className="delivery-info-value">{item.receiver}</span>
                                        </Row>
                                        <Row>
                                          <div className="mt-2">
                                            <label>Delivery Address</label>
                                            <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={item.delivery_address}>COPY ADDRESS</Button>
                                          </div>
                                          <span className="delivery-info-value">
                                            <address>
                                              {item.delivery_address}
                                            </address>
                                          </span>
                                        </Row>
                                        <Row>
                                          <label>Contact</label>
                                          <span>{item.contact}</span>
                                        </Row>
                                      </>
                                  }
                                </Card.Body>
                              </Card>
                            </Col>
                            : null
                        }
                        {
                          item.address_billing && user && user.role !== 'supplier' &&
                            <Col lg={4} className="d-print-none mt-3 mt-lg-0">
                              <Card className="greyCard label-strong">
                                <Card.Header className="greyCardHeader">Billing Address</Card.Header>
                                <Card.Body>
                                  <address>
                                    <label>{item.first_name_billing} {item.last_name_billing}</label><br />
                                    {item.address_billing}<br/>
                                    {item.phone_number_billing}
                                  </address>
                                </Card.Body>
                              </Card>
                            </Col>
                        }
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="overflowX-auto">
                <Col>
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Items</Card.Header>
                    <Card.Body className="mx-2 my-1">
                      {
                        item.fulfillment_status==='unfulfilled' && !item.cancelled_at &&
                          <>
                            <div className="mb-2">
                              <h4 className="d-inline-block mt-2" style={{color: '#FFC107'}}>UNFULFILLED</h4>
                            </div>
                            <div className="clearfix"></div>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <ManualOrderLineItems items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}} cancelledAt={item.cancelled_at}/>
                            </div>
                          </>
                      }
                      {
                        item.fulfillment_status==='fulfilled' && !item.cancelled_at &&
                          <>
                            <Row className="mb-2">
                              <Col lg={3}>
                                <h4 style={{color: '#4e8767'}}>FULFILLED</h4>
                              </Col>
                            </Row>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <ManualOrderLineItems displaySection={"fulfilled"} fulfillmentStatus={item.fulfillment_status} items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}}/>
                            </div>
                          </>
                      }
                      {
                        item.cancelled_at ?
                          <>
                            <Row className="mb-2">
                              <Col lg={3}>
                                <h4 style={{color: '#d15d5d'}}>CANCELLED</h4>
                              </Col>
                            </Row>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <ManualOrderLineItems displaySection={"cancelled"} fulfillmentStatus={item.fulfillment_status} items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}}/>
                            </div>
                          </>
                          :
                          null
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {
                item.fulfillment_status ==='fulfilled' &&
                  <Row className="overflowX-auto">
                    <Col>
                      <Card className="d-print-none tealCard shadow-sm fulfillments">
                        <Card.Header className="tealCardHeader">Fulfillments</Card.Header>
                        <Card.Body className="mx-2 my-0 py-0">
                          <div className="my-4">
                            <div>
                              <h4 className="d-inline-block" style={{color: '#80d7cf'}}>{item.order_id}</h4>
                              <Badge className="text-uppercase mx-3" style={{fontSize: '1em'}} bg={item.fulfillment_status === "fulfilled" ? "success" : "danger"}>{item.fulfillment_status === "fulfilled" ? "FULFILLED" : item.fulfillment_status}</Badge>
                            </div>
                            <Row className="flex-column flex-lg-row grey-label label-strong">
                              {
                                item.fulfillment && item.fulfillment.tracking_number &&
                                  <Col lg={6} className="d-grid mb-2 order-1">
                                    <label>Tracking Number</label>
                                    <span>{item.fulfillment.tracking_number}</span>
                                  </Col>
                              }
                              {
                                item.fulfillment && item.fulfillment.tracking_url &&
                                  <Col lg={6} className="d-grid mb-2 order-2 order-lg-3">
                                    <label>Tracking Url</label>
                                    <a href={item.fulfillment.tracking_url} target="_blank">{item.fulfillment.tracking_url}</a>
                                  </Col>
                              }
                              {
                                item.fulfillment && item.fulfillment.created &&
                                  <Col lg={6} className="d-grid mb-2 order-3 order-lg-2">
                                    <label>Fulfilled At</label>
                                    <span>{moment(item.fulfillment.created).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                                  </Col>
                              }
                              {
                                item.fulfillment && item.fulfillment.created_original &&
                                  <Col lg={6} className="d-grid mb-2 order-4">
                                    <label>Fulfilled At (Original)</label>
                                    <span>{moment(item.fulfillment.created_original).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                                  </Col>
                              }
                              {
                                item.updated &&
                                  <Col lg={6} className="d-grid mb-2 order-5">
                                    <label>Last Updated At</label>
                                    <span>{moment(item.updated).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                                  </Col>
                              }
                              {
                                item.fulfillment && item.fulfillment.pod &&
                                  <Col lg={6} className="d-grid mb-2 order-6">
                                    <label>Proof of Delivery or Image</label>
                                    <a href={item.fulfillment.pod} target="_blank">{item.fulfillment.pod}</a>
                                  </Col>
                              }
                            </Row>
                            <Row className="grey-label label-strong">
                              <Col className="d-grid">
                                <label>Item Details</label>
                                {item.item_details}
                              </Col>
                            </Row>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
              }
            </div>
          </>
      }
    </>
  );
}

export default ManualOrderDetail;
