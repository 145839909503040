import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import Profile from '../containers/Profile';
import AddGiftCardModal from '../components/AddGiftCardModal';
import MarkAsPaidModal from '../components/MarkAsPaidModal';
import TabHeader from '../components/TabHeader';
import ConfirmModal from '../components/ConfirmModal';
import GFBAlert from '../components/GFBAlert';
import { fadeTransition } from '../styles/transitions';
import { getProfile } from '../redux/slices/user';
import tickLogo from '../images/tick-circle.svg';
import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'

const CreditsPost = () => {
  const { item: profile } = useSelector(state => state.user);
  const { user } = useSelector(state => state.auth)
  const [giftcards, setGiftcards] = useState([])
  const [balance, setBalance] = useState(0)
  const [total, setTotal] = useState(0)
  const [purchases, setPurchases] = useState([])
  const [showAddGiftCardModal, setShowAddGiftCardModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  const [showConfirmRequest, setShowConfirmRequest] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);

  const isAdmin = user && user.role === 'admin'

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile())
    getCredits();
  }, [])

  const getCredits = async () => {
    return api('/credits/postpaid').then(res => {
      setGiftcards(res.giftcards)
      const balance = _.reduce(res.giftcards, (sum, giftcard) => {
        return sum + giftcard.balance
      }, 0)
      const total = _.reduce(res.giftcards, (sum, giftcard) => {
        return sum + giftcard.initial_value
      }, 0)
      setBalance(balance)
      setTotal(total)
      setPurchases(res.purchases)
    })
  }

  const renderCode = (col) => {
    return (
      <div className="position-relative" style={{paddingRight: '80px'}}>
        {col}
        <Button onClick={e => e.stopPropagation()} className="copy-to-clipboard py-1 position-absolute" style={{right: '0', width: '80px'}} variant="secondary" data-clipboard-text={col}>COPY</Button>
      </div>
    )
  }

  const onClickMarkAsPaid = (id) => {
    setSelectedPurchaseId(id);
    setShowPaymentModal(true);
  }

  const renderMarkAsPaid = (_, row) => {
    if (row.paid === true) {
      return
    }

    return (
      <div style={{paddingRight: '80px'}}>
        <Button onClick={() => onClickMarkAsPaid(row._id)} className="copy-to-clipboard" style={{width: '110px'}} variant="secondary">Mark as Paid</Button>
      </div>
    )
  }

  const renderInvoiceDownload = (col, row) => {

    if(row.paid === true && col){

      const query = encodeURIComponent(`link=${col}`)

      return (
        <Link to={`/invoice?${query}`} target="_blank">
          <Button variant="secondary" className="btn-br-6 d-print-none">View</Button>
        </Link>
      )
    }
  }

  const renderSalesReceipt = (col, row) => {

    if (row.paid === true && col && col.length > 0) {
      const receipts = _.map(col, receipt => {
        return receipt.link
      })

      const query = encodeURIComponent(`links=${receipts.join(',')}`)

      return (
        <Link to={`/receipt?${query}`} target="_blank">
          <Button variant="secondary" className="btn-br-6 d-print-none">View</Button>
        </Link>
      )
    }
  }

  const renderDate = (col) => {
    if(col){
      return moment(col).format('DD/MM/YYYY')
    }
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  const renderGiftcard = (row) => {

    const titleStyle = {
      "fontSize": "1.2em",
      "fontWeight": "300",
      "fontFamily": "Varela Round, Arial, sans-serif",
    }

    return (
      <div className="container-fluid" style={{color: '#5A5A5A'}}>
        <Timeline lineStyle={{background: 'none'}}>
          {
            _.map(_.reverse(row.timeline), event => {
              const { subject, order_name, order_id, amount } = event

              return (
                <TimelineEvent
                  key={event.created}
                  createdAt={moment(event.created).format('DD/MM h:mm a')}
                  titleStyle={titleStyle}
                  icon={<FontAwesomeIcon icon={solid('calendar')} transform="grow-4" style={{color: '#80d7cf'}}/>}
                  bubbleStyle={{background: 'none', border: 'none'}}
                >
                  <div className="buyer-speech-bubble" style={{whiteSpace: 'pre-line'}}>
                    {
                      subject === 'creation' &&
                        'A new gift card was issued.'
                    }
                    {
                      subject === 'transaction' &&
                        <span>
                          Paid {currency}{amount.toFixed(2)} with this gift card on order{" "}
                          <a href={`http://business.giftr.my/orders/${order_id}`}>{order_name}</a>
                        </span>
                    }
                  </div>
                </TimelineEvent>
              )
            })
          }
      </Timeline>
      </div>
    )
  }

  const handleOnClickAddGiftCard = () => {
    setShowAddGiftCardModal(true)
  }

  const renderAmount = (col) => {
    return `${currency} ${col/100}`
  }

  const confirmRequest = async () => {
    return api('/credits/request').then(() => {
      setRequestSuccess(true);
      setShowConfirmRequest(false);
    }).catch(err => {
      dispatch(addError(err))
    })
  }

  const giftcardsCol = [
    {
      dataField: 'giftcard_id',
      text: '',
      formatter: () => {
        return <Button variant="info" size="sm" style={{borderRadius: '100%'}}>
          <FontAwesomeIcon icon={solid('chevron-down')} transform="grow-4" style={{color: 'white'}}/>
          </Button>
      },
      headerStyle: {
        width: '36px',
      }
    },
    { dataField: 'created_at', text: 'Created', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
    { dataField: 'giftcard_id', text: 'ID', hidden: true, headerStyle: { width: '110px', } },
    { dataField: 'email', text: 'Email', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'code', text: 'Gift Card Code', formatter: renderCode, headerStyle: { width: '255px', } },
    { dataField: 'initial_value', text: 'Amount', formatter: (col) => { return `${currency} ${col}` }, headerStyle: { width: '110px', } },
    { dataField: 'expires_on', text: 'Expiry', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
    { dataField: 'balance', text: 'Balance', sort: true, formatter: (col) => { return `${currency} ${col}` }, headerStyle: { width: '110px', } },
  ];

  const purchaseCol = [
    { dataField: '_id', text: 'id', hidden: true },
    { dataField: 'created_at', text: 'created', hidden: true, sort: true, sortFunc: dateSort },
    { dataField: 'email', text: 'Email', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'paid_at', text: 'Paid At', formatter: renderDate, sort: true, sortFunc: dateSort, headerStyle: { width: '110px', } },
    { dataField: 'amount', text: 'Amount', formatter: renderAmount, headerStyle: { width: '110px', } },
    { dataField: 'paid_amount', text: 'Paid', formatter: renderAmount, headerStyle: { width: '110px', } },
    {
      dataField: 'financial_status',
      text: 'State',
      formatter: (col) => {
        if(col === 'paid'){
          return (
            <Badge bg="success text-uppercase">Paid</Badge>
          )
        } else {
          return (
            <Badge bg="warning text-uppercase">{col}</Badge>
          )
        }
      },
      headerStyle: {
        width: '110px',
      }
    },
    {
      dataField: 'fulfillment_status',
      text: 'Issued',
      formatter: (col) => {
        if(col === 'fulfilled'){
          return <Badge bg="success text-uppercase">yes</Badge>
        } else {
          return <Badge bg="danger text-uppercase">no</Badge>
        }
      },
      headerStyle: {
        width: '110px',
      }
    },
    { dataField: 'code', text: 'Payment', hidden: !isAdmin, formatter: renderMarkAsPaid, headerStyle: { width: '110px' } },
    { dataField: 'invoice', text: 'Invoice', hidden: !isAdmin, formatter: renderInvoiceDownload, headerStyle: { width: '135px', }, },
    { dataField: 'sales_receipt', text: 'Sales Receipt', hidden: !isAdmin, formatter: renderSalesReceipt, headerStyle: { width: '135px', }, },
  ];

  return (
    <>
      <Transition in={requestSuccess} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                Request success. You will be notified when our team issues more credits for you. 🤗
              </>
            } handleClose={() => {
              if(requestSuccess){
                setRequestSuccess(false)
              }
            }} />
        )}
      </Transition>
      <TabHeader
        title="Request Credits"
        tab_id="request_credits"
        children={
          <>
            {
              isAdmin &&
                <Button onClick={handleOnClickAddGiftCard} variant="primary" className="btn-br-6 d-print-none">Add Gift Card</Button>
            }
            {
              !isAdmin && profile && profile.company_verified &&
                <Card className="tealCard tealCardHeader align-items-center p-2 d-grid gap-1 grid-col-2-auto">
                  <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(51%) sepia(86%) saturate(2464%) hue-rotate(183deg) brightness(96%) contrast(99%)'}}/>
                  Verified
                </Card>
            }
            {
              !isAdmin && profile && !profile.company_verified &&
                <Card className="tealCard tealCardHeader align-items-center p-2 d-grid gap-1 grid-col-2-auto">
                  <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(69%) sepia(2%) saturate(0%) hue-rotate(108deg) brightness(96%) contrast(98%)'}}/>
                  Unverified
                </Card>
            }
            {
              !isAdmin && profile && profile.company_verified &&
                <Button onClick={() => setShowConfirmRequest(true)} variant="primary" className="btn-br-6 d-print-none">Request Credits</Button>
            }
          </>
        }
      />
      {
        (isAdmin || (profile && profile.company_verified)) &&
          <>
            <Row className="mx-0 justify-content-center">
              <Col lg={6}>
                <Card className="curve-border shadow-sm mb-3">
                  <Card.Body className='p-4'>
                    <h4>Balance</h4>
                    <Row className="">
                      <Col sm={12} className="text-center">
                        <p className="h3">{currency}{balance.toFixed(2)}</p>
                      </Col>
                      <Col sm={12} className="h4 text-center">
                        <p >/ {currency}{total.toFixed(2)}</p>
                      </Col>
                    </Row>
                    <div className="w-100" style={{
                      height: '40px',
                      border: '2px #81d8d0 solid',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      }}>
                      <div style={{
                        height: '100%',
                        backgroundColor: '#81d8d0',
                        width: `${(balance/total)*100}%`,
                        }}>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <h4>Credits History <span style={{fontSize: '0.6em', color: '#5a5a5a'}}>Click on
              [<FontAwesomeIcon icon={solid('chevron-down')} />]
              sign to view the order history</span></h4>
            <ToolkitProvider
              keyField='giftcard_id'
              data={giftcards}
              columns={giftcardsCol}
            >
              {
                props => (
                  <>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={giftcards.length > 10 ? paginationFactory({
                          showTotal: true,
                          sizePerPage: 10,
                          sizePerPageList: [{
                            text: '10', value: 10
                          }, {
                            text: '25', value: 25
                          }, {
                            text: '50', value: 50
                          }, {
                            text: 'All', value: giftcards.length
                          }],
                      }) : undefined}
                      hover
                      noDataIndication={() => 'There is no data to display'}
                      expandRow={{
                        renderer: renderGiftcard
                      }}
                      wrapperClasses="mb-3"
                      rowClasses="cursor-pointer"
                      defaultSorted={[{
                        dataField: 'created_at',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
            <h4>Credits Issued</h4>
            <ToolkitProvider
              keyField='_id'
              data={purchases}
              columns={purchaseCol}
            >
              {
                props => (
                  <>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={purchases.length > 10 ? paginationFactory({
                          showTotal: true,
                          sizePerPage: 10,
                          sizePerPageList: [{
                            text: '10', value: 10
                          }, {
                            text: '25', value: 25
                          }, {
                            text: '50', value: 50
                          }, {
                            text: 'All', value: purchases.length
                          }],
                      }) : undefined}
                      hover
                      wrapperClasses="mb-3"
                      noDataIndication={() => 'There is no data to display'}
                      defaultSorted={[{
                        dataField: 'created_at',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
      }
      {
        !isAdmin && profile && !profile.company_verified &&
          <>
            <p className="font-large">For company/organisations that are interested to place order on giftr.my using <b>pre-issued credits</b> to get billed and <b>pay later on monthly basis</b>, please complete the info below and upload documents required for verification purpose. Once approved, <b>monthly credits of RM1000</b> will be issued.</p>
            <Profile showHeader={false} showUserInfo={false} />
          </>
      }
      {
        showAddGiftCardModal &&
          <AddGiftCardModal showPurchaseId={false} postpaid={true} onSubmit={() => {
            setShowAddGiftCardModal(false);
            getCredits();
          }} onClose={() => setShowAddGiftCardModal(false)}/>
      }
      {
        showPaymentModal &&
          <MarkAsPaidModal isAdmin={isAdmin} onSubmit={() => {
            setShowPaymentModal(false);
            getCredits();
          }} selectedPurchase={_.find(purchases, p => p._id === selectedPurchaseId)} onClose={() => setShowPaymentModal(false)} />
      }
      {
        showConfirmRequest &&
          <ConfirmModal
            title="Confirm Request"
            noBtnTitle="Cancel"
            yesBtnTitle="Yes"
            onClickYes={() => confirmRequest()}
            onClose={() => setShowConfirmRequest(false)}
          >
            <p className="mb-0">Confirm requesting extra credits of RM1,000?</p>
          </ConfirmModal>
      }
    </>
  )
}

export default CreditsPost;
