import { Field, reduxForm, change, SubmissionError } from 'redux-form';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { Transition } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Input from '../components/Form/Input';
import GFBAlert from '../components/GFBAlert';
import FileUpload from '../components/Form/FileUpload';
import ChangePasswordModal from '../components/ChangePasswordModal';
import TabHeader from '../components/TabHeader';
import Loader from '../components/Loader';

import { getProfile } from '../redux/slices/user';
import { addError } from '../redux/slices/error';
import api from '../helpers/apiClient';
import { fadeTransition } from '../styles/transitions';
import tickLogo from '../images/tick-circle.svg';

let UserInfoForm = reduxForm({
  form: 'user_info',
  destroyOnUnmount: true,
})(({ submitUserInfo, handleSubmit, submitting, setShowChangePassword }) => {

  const customValidate = useCallback(
    value => ((typeof value === 'string' && value !== "") ? undefined : 'Required'),
    []
  );

  return (
    <form>
      <Row className="mx-0 justify-content-center">
        <Col lg={6}>
          <Card className="tealCard shadow-sm">
            <Card.Header className="tealCardHeader">
              <Row className="align-items-center justify-content-between">
                <Col xs="auto">User Info</Col>
                <Col xs="auto">
                  <Button variant="danger" className="btn-sm" onClick={() => setShowChangePassword(true)}>
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="m-2 d-grid gap-3">
              <div className="d-grid gap-3">
                <Field className="form-control" name="name" label="Name" placeholder="Name" type="text" component={Input} validate={customValidate} />
                <Field disabled className="form-control" name="email" label="Email" placeholder="Email" type="text" component={Input} />
                <Field className="form-control" name="phone_number" label="Phone Number" placeholder="Phone Number" type="text" component={Input} />
              </div>
              <div className='d-grid justify-content-center'>
              <Button
                onClick={handleSubmit(submitUserInfo)}
                className="px-4"
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                {submitting ? 'SAVING' : 'SAVE'}
              </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  )
})

UserInfoForm = connect(state => {
  return {
    initialValues: state.user.item,
  };
})(UserInfoForm);


let CompanyInfoForm = reduxForm({
  form: 'company_info',
  destroyOnUnmount: true,
})(({ submitCompanyInfo, handleSubmit, submitting, saveCompanyInfo, change }) => {
  const { item: profile } = useSelector(state => state.user);

  const handleFileUpload = (name, url) => {
    change(name, url);
  }

  return (
    <form>
      <Row className="mx-0 justify-content-center">
        <Col lg={6}>
          <Card className="tealCard shadow-sm mb-5">
            <Card.Header className="tealCardHeader">
              <Row className="align-items-center justify-content-between">
                <Col xs="auto">Company Info</Col>
                {
                  profile?.company_verified ?
                    <Col xs="auto" className="company-verified">
                      <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(51%) sepia(86%) saturate(2464%) hue-rotate(183deg) brightness(96%) contrast(99%)'}}/>
                      Verified
                    </Col>
                    :
                    <Col xs="auto" className="company-verified">
                      <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(69%) sepia(2%) saturate(0%) hue-rotate(108deg) brightness(96%) contrast(98%)'}}/>
                      Unverified
                    </Col>
                }
              </Row>
            </Card.Header>
            <Card.Body className="m-2">
              <div className="d-grid gap-3">
                <Field disabled={profile?.company_info_submitted} className="form-control" name="company_name" label="Company Name"  placeholder="Company Name" type="text" component={Input} />
                <Field disabled={profile?.company_info_submitted} className="form-control"  name="business_name" label="Business Name" placeholder="Business Name" type="text" component={Input}  />
                <Field disabled={profile?.company_info_submitted} className="form-control"  name="registration_no" label="Registration Number" placeholder="Registration Number" type="text" component={Input}  />
                <Field disabled={profile?.company_info_submitted} name="form_9" label="Form 9/Certificate of Incorporation" component={FileUpload} placeholder="Form 9/Certificate of Incorporation" handleOnChange={handleFileUpload} />
                <Field disabled={profile?.company_info_submitted} name="form_49" label="Form 49" placeholder="Form 49" component={FileUpload} handleOnChange={handleFileUpload}  />
                <Field disabled={profile?.company_info_submitted} name="authorised_letter" label={
                  <>
                    Authorised Letter {!profile?.company_info_submitted && <a className="btn btn-secondary btn-sm" href="https://docs.google.com/document/d/1Ev66D__89ri5rKVwdyND_nJDh44vW80RbWUB25fA0_Q/edit" target="_blank">Download Sample</a>}
                  </>
                  } placeholder="Authorised Letter" component={FileUpload} handleOnChange={handleFileUpload}  />
                <div className="grid-2-btns gap-1">
                  {
                    !profile?.company_info_submitted &&
                      <>
                        <Button
                          onClick={handleSubmit(saveCompanyInfo)}
                          className="px-4"
                          variant="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          {submitting ? 'SAVING' : 'SAVE'}
                        </Button>
                        <Button
                          onClick={handleSubmit(submitCompanyInfo)}
                          className="px-4"
                          variant="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          SUBMIT FOR VERIFICATION
                        </Button>
                      </>
                  }
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  )
})

CompanyInfoForm = connect(state => {
  return {
    initialValues: state.user.item,
  };
}, (dispatch) => {
  return bindActionCreators({ change }, dispatch);
})(CompanyInfoForm);


let Profile = ({ showHeader = true, showUserInfo = true }) => {
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const dispatch = useDispatch()
  const { item: profile, loading, loaded } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch])

  const submitUserInfo = async (values) => {
    const { name, phone_number } = values;
    const url = '/user/profile/update';

    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name, phone_number
      })
    }).then(() => {
      setSuccess(true)
      setAlert('Updated Successfully')
    }).catch(err => {
      dispatch(addError(err))
    });
  }

  const validateCompanyInfo = ({
    business_name,
    company_name,
    registration_no,
    form_9,
    form_49,
    authorised_letter
  }) => {
    let errors = {}

    if (!business_name) {
      errors.business_name = "Required"
    }
    if (!company_name) {
      errors.company_name = "Required"
    }
    if (!registration_no) {
      errors.registration_no = "Required"
    }
    if (!form_9) {
      errors.form_9 = "Required"
    }
    if (!form_49) {
      errors.form_49 = "Required"
    }
    if (!authorised_letter) {
      errors.authorised_letter = "Required"
    }

    return errors;
  }

  const submitCompanyInfo = async (values) => {
    let errors = validateCompanyInfo(values);

    if (Object.values(errors).length > 0) {
      throw new SubmissionError(errors)
    }

    const { business_name, company_name, registration_no, form_9, form_49, authorised_letter  } = values;
    const url = '/user/company/submit';

    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        business_name, company_name, registration_no, form_9, form_49, authorised_letter
      })
    }).then(() => {
      setSuccess(true)
      setAlert('Updated Successfully')
      dispatch(getProfile())
    }).catch(err => {
      dispatch(addError(err))
    });
  }

  const saveCompanyInfo = async (values) => {
    const { business_name, company_name, registration_no, form_9, form_49, authorised_letter  } = values;
    const url = '/user/company/save';

    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        business_name, company_name, registration_no, form_9, form_49, authorised_letter
      })
    }).then(() => {
      setSuccess(true)
      setAlert('Updated Successfully')
      dispatch(getProfile())
    }).catch(err => {
      dispatch(addError(err))
    });
  }

  const handleOnChangePassword = async (newPassword) =>  {

    let body = {
      password: newPassword
    }

    return api(`/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then((result) => {
      if(result.success){
        setSuccess(true)
        setAlert('Password Updated')
      } else {
        dispatch(addError("Fail to update password, please contact Giftr support"))
      }
      setShowChangePassword(false)
    })
  }

  return (
    <>
      <Transition in={success} timeout={150}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={alert} handleClose={() => {
              if(success){
                setSuccess(false)
              }
            }} />
        )}
      </Transition>
      {
        showHeader &&
          <TabHeader
            title="Profile"
            tab_id="profile"
          />
      }
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded && profile &&
          <div className="d-grid gap-3">
            {
              showUserInfo &&
                <UserInfoForm submitUserInfo={submitUserInfo} setShowChangePassword={setShowChangePassword} />
            }
            <CompanyInfoForm submitCompanyInfo={submitCompanyInfo} saveCompanyInfo={saveCompanyInfo} />
          </div>
      }
      {
        showChangePassword &&
          <ChangePasswordModal onClose={() => setShowChangePassword(false)} onClickSave={handleOnChangePassword}/>
      }
    </>
  )
}

export default Profile;
