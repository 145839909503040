import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from "react";
import { login, logout } from '../redux/slices/auth';

import Input from '../components/Form/Input';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors
}

const Login = ({ handleSubmit, submitting }) => {
  const { loading, loaded, user } = useSelector(state => state.auth);
  const { error, errorMsg } = useSelector(state => state.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(user){
      return navigate('/')
    }

    dispatch(logout())
  }, [user])

  const submit = values => {

    dispatch(login(values))
  }

  return (
    <div className="login-backdrop vh-100">
      <Row className="flex-column-reverse flex-lg-row w-100 ms-0" id="pwd-container">
        <Col xs={12} className="text-center">
          <form className="login-form desktop-login d-grid gap-3 mx-auto shadow-sm" onSubmit={handleSubmit(submit)}>
            <h4 className="mt-3 mb-5" style={{color:'#000'}}>Login to Giftr For Business</h4>
            {
              error &&
                <Alert variant="danger">
                  <div>{errorMsg}</div>
                </Alert>
            }
            <Field className="form-control" name="email" label="Email" placeholder="Email" type="email" isRenderLabel={false} component={Input} />
            <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" isRenderLabel={false} component={Input} />
            <div style={{textAlign:'right'}}>
              <a href="./forgot_password" target="_blank" style={{textDecoration: 'none', color: '#80d7cf', fontSize: '0.9em'}}>Forgot password?</a>
            </div>
            <Button
              type="submit"
              disabled={submitting}
              className="mt-4"
            >
              {submitting ? 'LOGGING IN...' : 'LOG IN'}
            </Button>
            <Link to="/signup" style={{textDecoration: 'none', color: '#80d7cf'}}>Sign Up</Link>
          </form>
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  form: 'login',
  validate,
  destroyOnUnmount: true,
})(Login)
