import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';
import qs from 'qs';

const initialState = {
  loading: false,
  loaded: false,
  items: [],
}

export const listOrders = createAsyncThunk('orders/list', async ({
  order_period_start,
  order_period_end,
  fulfillment_status,
  financial_status,
  email,
}, thunkAPI) => {
  const queryString = qs.stringify({
    order_period_start,
    order_period_end,
    fulfillment_status,
    financial_status,
    email,
  }, { skipNulls: true });

  try {
    return await api(`/orders?${queryString}`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(listOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = [...action.payload.orders]
        state.lastUpdated = action.payload.receivedAt
      })
      .addCase(listOrders.rejected, (state, action) => {
        state.loading = false;
        state.items = [];
      })
  }
})

export default ordersSlice.reducer
