import { createSlice } from '@reduxjs/toolkit';
import { login } from './auth';
import { listOrders } from './orders';
import { fetchManualOrderDetail } from './manualOrderDetail';

const initialState = {
  error: false,
  errorMsg: ''
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    addError: (state, action) => {
      const error = action.payload
      state.error = true;
      state.errorMsg = (error && error.message) ? error.message : typeof error === 'string' ? error : 'Something bad happened';
    },
    resetError: (state) => {
      state.error =  false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.rejected, (state, action) => {
        state.error = true;
        state.errorMsg = action.payload
      })
      .addCase(login.fulfilled, (state, action) => {
        state.error = false;
      })
      .addCase(listOrders.rejected, (state, action) => {
        if(action.payload){
          state.error = true;
          state.errorMsg = action.payload
        }
      })
      .addCase(fetchManualOrderDetail.rejected, (state, action) => {
        if(action.payload){
          state.error = true;
          state.errorMsg = action.payload
        }
      })
  }
})

export const { addError, resetError } = errorSlice.actions

export default errorSlice.reducer
