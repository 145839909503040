import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Col, Button, Badge } from 'react-bootstrap';
import { Transition } from 'react-transition-group';

import User from './User';
import GFBAlert from '../components/GFBAlert';
import Loader from '../components/Loader';
import ConfirmModal from '../components/ConfirmModal';
import api from '../helpers/apiClient';

import { listUsers } from '../redux/slices/users';
import { clearUser } from '../redux/slices/user';
import { fadeTransition } from '../styles/transitions';

const { SearchBar } = Search;

const Users = () => {
  const { items, loading, loaded } = useSelector(state => state.users);
  const { role } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const isAdmin = role === 'admin';

  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [createUser, setCreateUser] = useState({
    show: false,
    success: false,
    successId: null
  });

  const [editUser, setEditUser] = useState({
    show: false,
    success: false,
    userId: null,
    message: ''
  })

  useEffect(() => {
    dispatch(listUsers());
  }, [])

  useEffect(() => {
    if(createUser.success || editUser.success){
      dispatch(listUsers());
    }
  }, [createUser.success, editUser.success])

  const renderDetailLink = (cell, row) => {
    return (
      <a
        onClick={() => setEditUser({ show: true, userId: row.id })}
        href="#"
      >
        {cell}
      </a>
    )
  }

  const renderVerified = (cell, row) => {
    const { verified } = row

    return <Badge bg={verified ? "success" : "warning"} className="text-uppercase">{verified ? 'yes' : 'no'}</Badge>
  }

  const renderRemoveButton = (cell, row) => {
    return (
      <Button variant="danger" onClick={() => {
        setEmail(row.email)
        setId(row.id)
        setShowConfirmDelete(true)
      }}>Delete</Button>
    )
  }

  const deleteUser = () => {
    setShowConfirmDelete(false);
    return api(`/users/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      dispatch(listUsers());
    }).catch(err => {
      console.log(err);
    });
  }

  const columns = [
    { dataField: 'id', text: 'ID', sort: true, hidden: true, headerStyle: { width: '110px', } },
    { dataField: 'name', text: 'Name', sort: true, formatter: renderDetailLink, headerStyle: { width: '110px', } },
    { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: '110px', } },
    { dataField: 'role', text: 'Role', sort: true, hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'verified', formatter: renderVerified, hidden: role === 'client-staff', sort: true, text: 'Verified', headerStyle: { width: '85px', }, },
    { dataField: 'delete', text: '', formatter: renderRemoveButton, hidden: !isAdmin, headerStyle: { width: '110px', } },
  ];

  const options = {
    showTotal: true,
    sizePerPage: 10,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: 'All', value: items && items.length }
    ],
  };

  return (
    <>
      <Transition in={editUser.success} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                {editUser.message}
              </>
            } handleClose={() => {
              if(createUser.success){
                setEditUser({ ...editUser, success: false, email: '' })
              }
            }} />
        )}
      </Transition>
      <Transition in={createUser.success} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                Created new user.{" "}
                {
                  role === 'client' &&
                    <span>Verification link has been sent to their email.</span>
                }
              </>
            } handleClose={() => {
              if(createUser.success){
                setCreateUser({ ...createUser, success: false })
              }
            }} />
        )}
      </Transition>
      <div className="row mt-3 mb-4">
        <div className="col-xs-12 col-md-9">
          <h2 className="sub-header">Users</h2>
        </div>
      </div>
      {
        role === 'admin' &&
          <Container fluid className="d-flex flex-column align-items-lg-end">
            <Col sm="auto">
              <Button
                variant="primary"
                className="w-100 btn-br-6"
                onClick={() => setCreateUser({ ...createUser, show: true })}
              >
                Create User
              </Button>
            </Col>
          </Container>
      }
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded &&
          <ToolkitProvider
            keyField='id'
            data={items}
            columns={columns}
            search
          >
            {
              props => (
                <>
                  <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} placeholder="Search"/>
                  <BootstrapTable
                    { ...props.baseProps }
                    bordered={ false }
                    pagination={paginationFactory(options)}
                    hover
                  />
                </>
              )
            }
          </ToolkitProvider>
      }
      {
        createUser.show &&
          <User
            isAdmin={isAdmin}
            isCreate={true}
            onClose={() => setCreateUser({ ...createUser, show: false })}
            onSubmit={id => setCreateUser({
              show: false,
              success: true,
              successId: id
            })}/>
      }
      {
        editUser.show &&
          <User
            isAdmin={isAdmin}
            onClose={() => {
              dispatch(clearUser())
              setEditUser({ ...editUser, show: false })
            }}
            onSubmit={message => setEditUser({
              show: false,
              success: true,
              message,
            })}
            userId={editUser.userId}
          />
      }
      { showConfirmDelete &&
        <ConfirmModal title="Delete User" onClose={() => setShowConfirmDelete(false)} onClickYes={deleteUser}>
          <p>Confirm delete this user ({email})?</p>
        </ConfirmModal>
      }
    </>
  )
}

export default Users;
