import React, { Component } from 'react';
import _ from 'lodash'
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'
// const shopifyAdminDomain = window.location.hostname === 'merchant.giftr.my' ? 'https://helpr-asia.myshopify.com/admin/products' : 'https://giftr-singapore.myshopify.com/admin/products'
const giftrDomain = window.location.hostname === 'business.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'


const LineItems = ({ orderId, user, items, style, fulfillmentStatus, cancelledAt, displaySection, handleOnClickAdjustment, noteAttributes, onPrintMessage }) => {

  return (
    <table className="table" style={style}>
      <tbody>
        {
          items.map(item => {
            let shouldAlert = item.ship_by ? moment(item.ship_by, 'DD/MM/YYYY').isSameOrBefore(moment(), 'day') : false

            return (
              <tr key={item.id}>
                <td width="70">
                  {
                    item.image &&
                  <div className="line-item-image">
                    <img src={item.image} alt={item.title}/>
                    <span className="badge badge-quantity">{item.quantity}</span>
                  </div>
                }
                </td>
                <td className="line-item-detail">
                  <div><a target="_blank" href={`${giftrDomain}/products/${item.handle}`}><strong>{item.title} - {item.quantity} Unit(s)</strong></a></div>
                  <p>{item.name}</p>
                  {
                    item.sku ?
                    <div className="mb-2">SKU: {item.sku}</div>
                    :
                    null
                  }
                  {
                    item.vendor ?
                    <div className="d-grid mb-2">
                      <label>Vendor Name</label>
                      <span>{item.vendor}</span>
                    </div>
                    :
                    null
                  }
                  {
                    item.est_delivery ?
                    <div className="d-grid mb-2">
                      <label>Est. Delivery</label>
                      <span>{item.est_delivery}</span>
                    </div>
                    :
                    null
                  }
                  {
                    item.ship_by ?
                    <div className="d-grid mb-2">
                      <label>Ship By</label>
                      { shouldAlert && displaySection !== 'fulfilled' && _.lowerCase(fulfillmentStatus) !== 'fulfilled' && !cancelledAt ? <span style={{color: '#d15d5d'}}>{item.ship_by}</span> : <span>{item.ship_by}</span>}
                    </div>
                    :
                    null
                  }
                  {
                    item.grams ?
                    <div className="d-grid mb-2">
                      <label>Weight</label>
                      <span>{item.grams} grams</span>
                    </div>
                    :
                    null
                  }
                  {
                    item.properties ?

                    item.properties.map((property, i) => {
                      if(property.name === 'Delivery'){
                        const editedDeliveryDate = _.find(noteAttributes, note => _.includes(note.name, `deliverydate_${item.id}`))

                        return (
                          <div className="d-grid" key={i}>
                            <label>{`Delivery${editedDeliveryDate ? ' (Edited)' : ''}`}</label>
                            <p className="order-detail-item-desc">{editedDeliveryDate ? editedDeliveryDate.value : property.value}</p>
                          </div>
                        )
                      }

                      return (

                        <div className="d-grid" key={i}>
                          <label>{property.name}</label>
                          <p className="order-detail-item-desc">
                            {property.value}
                          </p>

                        </div>)
                    })
                    :
                    null
                  }
                  {
                    (item.adjustment_item_price || item.adjustment_shipping) &&
                    <>
                    <hr/>
                    <div className="d-grid gap-2 mb-2">
                      {
                        item.adjustment_item_price &&
                        <div className="d-grid gap-1">
                          <label>Item Price Adjustment</label>
                          <span>{currency} {item.adjustment_item_price.toFixed(2)}</span>
                        </div>
                      }
                      {
                        item.adjustment_shipping &&
                        <div className="d-grid gap-1">
                          <label>Shipping Adjustment</label>
                          <span>{currency} {item.adjustment_shipping.toFixed(2)}</span>
                        </div>
                      }
                      {
                        item.adjustment_remarks &&
                        <div className="d-grid gap-1">
                          <label>Adjustment Remarks</label>
                          <span>{item.adjustment_remarks}</span>
                        </div>
                      }
                    </div>
                    </>
                  }
                </td>
                <td className="text-end" style={{verticalAlign: "middle"}}>
                  {item.ready_to_ship && <div><Badge bg="success" className="text-uppercase mb-2">Accepted</Badge><br/></div>}
                  <span className="d-print-none">{currency} {item.price} </span>x {item.quantity > 1 ? (<span className="highlight">{item.quantity}</span>) : item.quantity } Unit(s)<br/><span className="d-print-none">= {currency} {(item.price * item.quantity).toFixed(2)}</span><br/>
                  {/* { user && user.role === 'admin' && <Button className="d-print-none btn-br-6 mt-2" variant="secondary" onClick={() => {handleOnClickAdjustment(item)}}>Adjustment</Button> } */}
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}

export default LineItems;
