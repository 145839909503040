import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Row, Col } from 'react-bootstrap';

import EditHelpModal from './EditHelpModal';
import HelpModal from './HelpModal';
import api from '../helpers/apiClient';
import bookIcon from '../images/book-icon.svg';
import { listQuickHelps } from '../redux/slices/quickHelp';

const TabHeader = ({ title, tab_id, children }) => {
  const { items: quick_helps } = useSelector(state => state.quickHelp)
  const { user } = useSelector(state => state.auth)
  const [showQuickHelps, setShowQuickHelps] = useState(false);
  const [showEditHelp, setShowEditHelp] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [value, setValue] = useState('');
  const [saved, setSaved] = useState('');
  const dispatch = useDispatch();

  const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

  useEffect(() => {
    if(quick_helps && quick_helps.length){
      const tab = _.find(quick_helps, i => i.tab_id === tab_id)

      setShowQuickHelps(tab && tab.pages && tab.pages.length > 0);
    }
  }, [quick_helps])

  const onClickEditHelp = () => {
    setShowEditHelp(true);
  }

  const onClickSave = async () => {
    let lines = _.filter(_.split(value, '\n'), i => i.length > 0)

    let pages = []
    _.each(lines, line => {
      const lineSplit = line.split('|')

      if (lineSplit.length > 1){
        pages.push({
          title: lineSplit[0].trim(),
          link: lineSplit[1].trim(),
        })
      }
    })

    await api(`/quick_help/edit/${tab_id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pages })
    })

    setSaved('Saved');
    dispatch(listQuickHelps());
  }

  const onClickQuickHelp = () => {
    setShowHelp(true);
  }

  const handleOnCloseEditHelp = () => {
    setShowEditHelp(false);
    setSaved('');
  }

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col xs={12} sm={5}>
          <h2 className="sub-header">{title}</h2>
        </Col>
        <Col xs={12} sm={7} className="header-btns d-flex flex-row-reverse gap-3 align-items-end">
          {children}
          {
            showQuickHelps &&
              <Button variant="secondary" className="d-grid gap-1" onClick={onClickQuickHelp} style={{gridTemplateColumns: '85px auto'}}>
                Quick Help
                <img src={bookIcon} style={{height: '1.5rem'}}/>
              </Button>
          }
          {
            isAdmin &&
              <Button variant="primary" onClick={onClickEditHelp}>Edit Help</Button>
          }
        </Col>
      </Row>
      {
        showEditHelp &&
          <EditHelpModal onClose={handleOnCloseEditHelp} value={value} setValue={setValue} onClickSave={onClickSave} title={title} tab_id={tab_id} saved={saved} setSaved={setSaved}/>
      }
      {
        showHelp &&
          <HelpModal onClose={() => setShowHelp(false)} tab_id={tab_id}/>
      }
    </>
  )
}

export default TabHeader;
