import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useState } from "react";
import { Transition } from 'react-transition-group';

import GFBAlert from '../components/GFBAlert';
import Input from '../components/Form/Input';
import { addError } from '../redux/slices/error';
import api from '../helpers/apiClient';
import { fadeTransition } from '../styles/transitions';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required';
  }
  return errors
}

const ForgotPassword = ({ handleSubmit, submitting }) => {
  const { error, errorMsg } = useSelector(state => state.error);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const submit = async (values) => {
    const { email } = values;
    return api('/auth/reset', {
      method: "post",
      body: JSON.stringify({ email })
    }).then(() => {
      setSuccess(true)
    }).catch(e => {
      dispatch(addError(e));
    })
  }

  return (
    <>
      <Transition in={success} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success"
            alert="We have sent a new password to your email address. Please check your email."
          />
        )}
      </Transition>
      <div className="login-backdrop vh-100">
        <Row className="flex-column-reverse flex-lg-row w-100 ms-0" id="pwd-container">
          <Col xs={12} className="text-center">
            <form className="login-form desktop-login d-grid gap-3 mx-auto shadow-sm" onSubmit={handleSubmit(submit)}>
              <h4 className="mt-3 mb-5" style={{color:'#000'}}>Reset Password</h4>
              {
                error &&
                  <Alert variant="danger">
                    <div>{errorMsg}</div>
                  </Alert>
              }
              <Field className="form-control" name="email" label="Email" placeholder="Email" type="email" isRenderLabel={false} component={Input} />
              <Button
                type="submit"
                disabled={submitting || success}
                className="mt-5"
              >
                RESET
              </Button>
            </form>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default reduxForm({
  form: 'forgot_password',
  validate,
  destroyOnUnmount: true,
})(ForgotPassword)
