import { useEffect, useState } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';

const Catalog = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: 'helpr-asia.myshopify.com',
        storefrontAccessToken: '46287e7d69dbda678b751411c85775eb',
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('collection', {
          id: '287842140321',
          node: document.getElementById('collection-component-1655287964676'),
          moneyFormat: 'RM%7B%7Bamount%7D%7D',
          options: {
            "product": {
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    "width": "calc(25% - 20px)"
                  },
                  ":has(button:disabled)": {
                    "display": "none"
                  }
                },
                "button": {
                  "font-weight": "bold",
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px",
                  ":hover": {
                    "background-color": "#5cd1c6"
                  },
                  "background-color": "#81d8d0",
                  ":focus": {
                    "background-color": "#5cd1c6"
                  },
                  "border-radius": "5px"
                },
                "quantityInput": {
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px"
                }
              },
              "buttonDestination": "onlineStore",
              "contents": {
                "options": false
              },
              "text": {
                "button": "View product"
              }
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                },
              },
              "text": {
                "nextPageButton": 'Show more',
              },
              // "events":{
              //   "afterInit": function(productSet){
              //     console.log(productSet);
              //   },
              //   "loadNextPage": async function(productSet){
              //     const collection = await client.collection.fetchWithProducts("gid://shopify/Collection/287842140321", {productsFirst: 100})
              //     console.log(productSet);
              //     productSet.products = collection.products
              //   }
              // }
            },
            "option": {},
            "toggle": {
              "styles": {
                "toggle": {
                  "font-weight": "bold",
                  "background-color": "#4caad8",
                  ":hover": {
                    "background-color": "#4499c2"
                  },
                  ":focus": {
                    "background-color": "#4499c2"
                  }
                },
                "count": {
                  "font-size": "14px"
                }
              }
            },
            "lineItem": {
              "styles": {
                "variantTitle": {
                  "color": "#4c4c4c"
                },
                "title": {
                  "color": "#4c4c4c"
                },
                "price": {
                  "color": "#4c4c4c"
                },
                "fullPrice": {
                  "color": "#4c4c4c"
                },
                "discount": {
                  "color": "#4c4c4c"
                },
                "discountIcon": {
                  "fill": "#4c4c4c"
                },
                "quantity": {
                  "color": "#4c4c4c"
                },
                "quantityIncrement": {
                  "color": "#4c4c4c",
                  "border-color": "#4c4c4c"
                },
                "quantityDecrement": {
                  "color": "#4c4c4c",
                  "border-color": "#4c4c4c"
                },
                "quantityInput": {
                  "color": "#4c4c4c",
                  "border-color": "#4c4c4c"
                }
              }
            }
          },
        });
      }).then(() => setLoading(false));
    }
  }, [])

  return (
    <>
      <TabHeader
        title="Corporate Gifts & Bulk Purchase"
        tab_id="bulk_catalogue"
        children={
              <a className="d-block" href="https://giftr.my/collections/corporate-gifts-bulk-purchase" target="_blank">
                <Button variant="primary w-100" className="btn-br-6 d-print-none">View All</Button>
              </a>
        }
      />
      {
        loading &&
          <Loader/>
      }
      <div id='collection-component-1655287964676'></div>
    </>
  )
}

export default Catalog;
