import { Nav, Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/GFB-Logo.svg';
import { useSelector } from 'react-redux';

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

const Sidebar = () => {
  const { pathname } = useLocation();
  const { user } = useSelector(state => state.auth)

  return (
    <Nav className="nav-sidebar flex-column mb-0">
      <img src={logo} width="140" className="mx-auto" style={{marginBottom:'27px'}}/>
      <Nav.Link
        className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'}`}
        as={Link}
        to='/dashboard'
      ><FontAwesomeIcon icon={solid('house')}/>
        Dashboard</Nav.Link>
      <Nav.Link
        className={`${isActiveRoute(pathname, '/orders') && 'active'}`}
        as={Link}
        to='/orders'
      ><FontAwesomeIcon icon={solid('clipboard')} transform="grow-2"/>
        Orders</Nav.Link>
      <Nav.Link
        className={`${isActiveRoute(pathname, '/credits') && 'active'}`}
        as={Link}
        to='/credits'
      ><div><FontAwesomeIcon icon={solid('credit-card')}/></div>
        Purchase Credits</Nav.Link>
      <Nav.Link
        className={`${isActiveRoute(pathname, '/request_credits') && 'active'}`}
        as={Link}
        to='/request_credits'
      ><div><FontAwesomeIcon icon={solid('money-bills')} transform="grow-2"/></div>
        Request Credits</Nav.Link>
      <Card className="tealCard">
        <Accordion.Item style={{border:'none'}} eventKey="0">
          <Accordion.Header style={{color: '#5a5a5a'}}>
            <FontAwesomeIcon icon={solid('cake-candles')} transform="grow-2"/>
            Single Order
          </Accordion.Header>
          <Card.Body className="p-0">
            <a
              href="https://giftr.my"
              target="_blank"
              className="nav-link pe-2"
              style={{padding: '0.5rem 0 0.5rem 3.35rem', textDecoration: 'none'}}
            >
              Browse Giftr.my
            </a>
          </Card.Body>
        </Accordion.Item>
      </Card>
      <Card className="tealCard">
        <Accordion.Item style={{border:'none'}} eventKey="0">
          <Accordion.Header style={{color: '#5a5a5a'}}>
            <FontAwesomeIcon icon={solid('gift')}/>
            Bulk Order
          </Accordion.Header>
          <Card.Body className="p-0">
            <Nav.Link
              className={`${isActiveRoute(pathname, '/catalogue') && 'active'}`}
              as={Link}
              to='/catalogue'
              style={{paddingLeft: '3.35rem'}}
            >
              Browse Catalogue</Nav.Link>
          </Card.Body>
        </Accordion.Item>
      </Card>
      <Nav.Link
        className={`${isActiveRoute(pathname, '/profile') && 'active'}`}
        as={Link}
        to='/profile'
      ><FontAwesomeIcon icon={solid('user')}/>
        Profile</Nav.Link>
      <Nav.Link
        className={`${isActiveRoute(pathname, '/rewards') && 'active'}`}
        as={Link}
        to='/rewards'
      ><FontAwesomeIcon icon={solid('crown')}/>
        Rewards Program</Nav.Link>
      {
        user && user.role === 'admin' &&
          <Accordion className="nav-accordion" defaultActiveKey="0">
            <Accordion.Item style={{border:'none'}} eventKey="0">
              <Accordion.Header style={{color: '#80d7cf'}}>
              <FontAwesomeIcon icon={solid('wrench')}/>
              Admin Tools
              </Accordion.Header>
              <Accordion.Body className="pe-2" style={{marginLeft: '2.3rem'}}>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/users') && 'active'}`}
                  as={Link}
                  to="/users"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Users
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/logs') && 'active'}`}
                  as={Link}
                  to="/logs"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                > Activity Log
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      }
      <Nav.Link className={`${isActiveRoute(pathname, '/logout') && 'active'} logout-btn`}
        as={Link}
        to="/logout"
      ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="flip-h"/>
        Log Out
      </Nav.Link>
    </Nav>
  )
}

export default Sidebar;
