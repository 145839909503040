import { useDispatch, useSelector, connect } from "react-redux";
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import { Modal, Row, Card, Col, Button, Badge } from 'react-bootstrap';
import { useEffect } from "react";
import { bindActionCreators } from 'redux';

import Input from '../components/Form/Input';
import Select from '../components/Form/Select';
import FileUpload from '../components/Form/FileUpload';

import userRolesOptions from '../data/userRoles.json';
import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';
import { getUserDetail } from '../redux/slices/user';
import tickLogo from '../images/tick-circle.svg';

const validate = values => {
  const errors = {}
  if(!values.name) {
    errors.name = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  if(!values.role){
    errors.role = 'Required';
  }
  if(values.role === 'client-staff' && !values.parent_client){
    errors.parent_client = 'Required';
  }
  return errors
}

let User = ({ isAdmin, roleValue, userId, isCreate, onClose, onSubmit, handleSubmit, submitting, change, verifying }) => {
  const { item } = useSelector(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId){
      dispatch(getUserDetail(userId))
    }
  }, [userId])

  const submit = async (values) => {
    const { email, phone_number, password, role, name, parent_client, business_name, company_name, registration_no } = values;
    const url = '/users';

    if(isCreate){
      return api(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email, phone_number, password, role, name, parent_client, business_name, company_name, registration_no
        })
      }).then(res => {
        onSubmit(res.user.id);
      }).catch(err => {
        dispatch(addError(err))
      });
    } else {
      return api(`${url}/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email, phone_number, password, role, name, parent_client, business_name, company_name, registration_no
        })
      }).then(res => {
        onSubmit(`Edited user ${res.user.email}`);
      }).catch(err => {
        dispatch(addError(err))
      });
    }
  }

  const handleFileUpload = (name, url) => {
    change(name, url);
  }

  const handlePassword = (password) => {
    change("password", password);
  }

  const onClickVerifyCompany = async (e) => {
    e.preventDefault();

    return api(`/users/${userId}/company`, {
      method: 'GET',
    }).then(res => {
      onSubmit(`Verified company for ${res.user.email}`);
    }).catch(err => {
      dispatch(addError(err))
    });
  }

  return (
    <Modal show={true} onHide={() => onClose()} size="lg">
      <Modal.Header className="mx-2 mt-1 merchant-modal-header" closeButton>
        { isCreate ?
          <h4>Create User</h4>
          :
          <h4>User Detail</h4>
        }
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(submit)} className="d-grid gap-3">
          <Row className="mx-0 justify-content-center">
            <Col lg={8}>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">User Info</Card.Header>
                <Card.Body className="m-2">
                  {
                    !isCreate && item?.verified &&
                      <div className="d-flex justify-content-end">
                        <Badge bg="success text-uppercase">Verified</Badge>
                      </div>
                  }
                  <div className="d-grid gap-3">
                    <Field className="form-control" required name="name" label="Name" placeholder="Name" type="text" component={Input} />
                    <Field className="form-control" name="email" label="Email" placeholder="Email" type="text" component={Input} />
                    <Field className="form-control" name="phone_number" label="Phone Number" placeholder="Phone Number" type="text" component={Input} />
                    {
                      isCreate &&
                        <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" component={Input} isPassword={true} handlePassword={handlePassword}/>
                    }
                    {
                      isAdmin &&
                        <>
                          <Field className="form-control" name="role" label="Role" type="select" component={Select} options={userRolesOptions} defaultOptionName="Select a user role" />
                          {
                            roleValue === 'client-staff' &&
                              <Field className="form-control" name="parent_client" label="Client" placeholder="Email" type="text" component={Input} />
                          }
                        </>
                    }
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0 justify-content-center">
            <Col lg={8}>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">
                  <Row className="align-items-center justify-content-between">
                    <Col xs="auto">Company Info</Col>
                    {
                      item?.company_verified ?
                        <Col xs="auto" className="company-verified">
                          <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(51%) sepia(86%) saturate(2464%) hue-rotate(183deg) brightness(96%) contrast(99%)'}}/>
                          Verified
                        </Col>
                        :
                        <Col xs="auto" className="company-verified">
                          <img src={tickLogo} width="23" className="mx-auto" style={{filter: 'invert(69%) sepia(2%) saturate(0%) hue-rotate(108deg) brightness(96%) contrast(98%)'}}/>
                          Unverified
                        </Col>
                    }
                  </Row>
                </Card.Header>
                <Card.Body className="m-2">
                  <div className="d-grid gap-3">
                    <Field className="form-control" required name="company_name" label="Company Name" placeholder="Company Name" type="text" component={Input} />
                    <Field className="form-control" required name="business_name" label="Business Name" placeholder="Business Name" type="text" component={Input} />
                    <Field className="form-control" required name="registration_no" label="Registration Number" placeholder="Registration Number" type="text" component={Input} />
                    <Field name="form_9" label="Form 9/Certificate of Incorporation" component={FileUpload} placeholder="Form 9/Certificate of Incorporation" handleOnChange={handleFileUpload}/>
                    <Field name="form_49" label="Form 49" placeholder="Form 49" component={FileUpload} handleOnChange={handleFileUpload} />
                    <Field name="authorised_letter" label="Authorised Letter" placeholder="Authorised Letter" component={FileUpload} handleOnChange={handleFileUpload} />
                    <Button
                      variant="primary"
                      disabled={verifying}
                      onClick={onClickVerifyCompany}
                    >
                      {verifying ? 'VERIFYING' : 'VERIFY'}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-2 mt-4">
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}
            >
              {isCreate ?
                <>{submitting ? 'SUBMITTING' : 'SUBMIT'}</>
                :
                <>{submitting ? 'UPDATING' : 'UPDATE'}</>
              }

            </Button>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

User = reduxForm({
  form: 'user',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(User)

const selector = formValueSelector('user')

export default connect((state, props) => {
  const { isAdmin, isCreate } = props
  const roleValue = selector(state, 'role')

  return {
    roleValue,
    initialValues: isCreate ? (!isAdmin ? {role: 'client-staff'} : {}) : state.user.item
  };
}, (dispatch) => {
  return bindActionCreators({ change }, dispatch);
})(User);
