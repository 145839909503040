import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';
import qs from 'qs';

const initialState = {
  loading: false,
  loaded: false,
  item: null
}

export const getDashbboard = createAsyncThunk('dashboard/client', async (email, thunkAPI) => {
  const queryString = qs.stringify({
    email,
  }, { skipNulls: true });

  try {
    return await api(`/dashboard/client?${queryString}`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const clientDashboard = createAsyncThunk('dashboard/client', async (_, thunkAPI) => {
  try {
    return await api('/dashboard/client', {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(clientDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(clientDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.item = action.payload.dashboard;
        state.lastUpdated = action.payload.receivedAt;
      })
      .addCase(clientDashboard.rejected, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.item = null;
      })
  }
})

export default dashboardSlice.reducer
