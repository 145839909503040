import { Navbar, Nav, Offcanvas, Container, Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import logo from '../images/Giftr-Logo-Main.svg'

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

const Header = () => {
  const { user } = useSelector(state => state.auth);

  const [headerState, setHeaderState] = useState({
    expanded: false,
    navExpanded: false,
    announcements: []
  })

  const { pathname } = useLocation();

  const handleToggleClose = () => {
    const { expanded } = headerState
    expanded ?
      setHeaderState({
        ...headerState,
        expanded: false
      })
      :
      setHeaderState({
        ...headerState,
        expanded: true
      })
  }

  return (
    <>
      <Navbar className="header py-0" bg="light" fixed="top" expand={false} style={{width: '100%'}}>
        <Container className="d-print-none d-md-none">
          <Navbar.Brand href="/">
            <img src={logo} alt="Giftr For Business" className="header-branding align-text-bottom"/>
            <h4 style={{display: 'inline-block', color: '#80d7cf'}}>For Business</h4>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => handleToggleClose()}/>
          <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end" onHide={() => handleToggleClose()} show={headerState.expanded}>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {
                  user && (user.role === 'admin' || user.role === 'client') &&
                    <Nav.Link
                      className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'}`}
                      as={Link}
                      to='/dashboard' onClick={() => handleToggleClose()}
                    ><FontAwesomeIcon icon={solid('house')} style={{margin: '0 14px'}}/>Dashboard</Nav.Link>
                }
                {
                  user && (user.role === 'admin' || user.role === 'client') &&
                    <Nav.Link
                      className={`${(isActiveRoute(pathname, '/orders')) && 'active'}`}
                      as={Link}
                      to='/orders' onClick={() => handleToggleClose()}
                    ><FontAwesomeIcon icon={solid('clipboard')} transform="grow-2" style={{margin: '0 18px'}}/>Orders</Nav.Link>
                }
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/credits')) && 'active'}`}
                  as={Link}
                  to='/credits' onClick={() => handleToggleClose()}
                ><FontAwesomeIcon icon={solid('credit-card')} transform="grow-2" style={{margin: '0 15px'}}/>Purchase Credits</Nav.Link>
                {
                  user && user.role === 'admin' &&
                    <Nav.Link
                      className={`${isActiveRoute(pathname, '/request_credits') && 'active'}`}
                      as={Link}
                      to='/request_credits' onClick={() => handleToggleClose()}
                    ><FontAwesomeIcon icon={solid('money-bills')} transform="grow-2" style={{margin: '0 14px'}}/>
                      Request Credits</Nav.Link>
                }
                <Card className="tealCard">
                  <Accordion.Item style={{border:'none'}} eventKey="0">
                    <Accordion.Header style={{color: '#5a5a5a'}}>
                      <FontAwesomeIcon icon={solid('cake-candles')} transform="grow-2" style={{marginRight: '18px'}}/>
                      Single Order
                    </Accordion.Header>
                    <Card.Body className="p-0">
                      <a
                        href="https://giftr.my"
                        rel="noreferrer"
                        target="_blank"
                        className="nav-link pe-2"
                        style={{padding: '0.5rem 0 0.5rem 3.35rem', fontSize: '0.9em', textDecoration: 'none'}}
                      >
                        Browse Giftr.my
                      </a>
                    </Card.Body>
                  </Accordion.Item>
                </Card>
                <Card className="tealCard">
                  <Accordion.Item style={{border:'none'}} eventKey="0">
                    <Accordion.Header style={{color: '#5a5a5a'}}>
                      <FontAwesomeIcon icon={solid('gift')} style={{marginRight: '15px'}}/>
                      Bulk Order
                    </Accordion.Header>
                    <Card.Body className="p-0">
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/catalogue') && 'active'}`}
                        as={Link}
                        to='/catalogue' onClick={() => handleToggleClose()}
                        style={{paddingLeft: '3.35rem'}}
                      >
                        Browse Catalogue</Nav.Link>
                    </Card.Body>
                  </Accordion.Item>
                </Card>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/profile') && 'active'}`}
                  as={Link}
                  to='/profile' onClick={() => handleToggleClose()}
                ><FontAwesomeIcon icon={solid('user')} style={{margin: '0 17px'}}/>
                  Profile</Nav.Link>
                {
                  user && user.role === 'admin' &&
                    <Nav.Link
                      className={`${isActiveRoute(pathname, '/users') && 'active'}`}
                      as={Link}
                      to='/users' onClick={() => handleToggleClose()}
                    ><FontAwesomeIcon icon={solid('users')} style={{margin: '0 17px'}}/>
                      Users</Nav.Link>
                }
                <Nav.Link className={`${isActiveRoute(pathname, '/logs') && 'active'}`} style={{color: '#D15D5D'}}
                  as={Link} to="/logout"><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="flip-h" className="mx-3"/>
                  Log Out
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
