import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Badge, Button, Accordion, Form, Card, Row, Col, Container, Modal } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { DateRangePicker } from 'react-dates';
import "react-dates/lib/css/_datepicker.css";
import qs from 'qs';
import * as Promise from 'bluebird';

import Loader from '../components/Loader';
import OverlayLoader from '../components/OverlayLoader';
import TabHeader from '../components/TabHeader';

import { listOrders } from '../redux/slices/orders';
import promptIcon from '../images/adminPrompt.svg';
import { addError } from '../redux/slices/error';
import api from '../helpers/apiClient';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'
const searchPlaceholder = 'johndoe@gmail.com'
const giftrDomain = window.location.hostname === 'business.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

const fulfillmentStatusOptions = {
  any: "Any",
  fulfilled: "Fulfilled",
  unfulfilled: "Unfulfilled",
  partial: "Partial",
  cancelled: "Cancelled",
};

const financialStatusOptions = {
  any: "Any",
  authorized: "Authorized",
  pending: "Pending",
  paid: "Paid",
  partially_paid: "Partially Paid",
  refunded: "Refunded",
  voided: "Voided",
  unpaid: "Unpaid",
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Orders = () => {
  const { items, loading, loaded } = useSelector(state => state.orders);
  const { user } = useSelector(state => state.auth);
  const isAdmin = user && user.role === 'admin'

  const [email, setEmail] = useState('')
  const [filterPrompt, setFilterPrompt] = useState(false)
  const [fulfillmentStatus, setFulfillmentStatus] = useState('any')
  const [financialStatus, setFinancialStatus] = useState('any')
  const [focusedOrderPeriod, setFocusedOrderPeriod] = useState(null)
  const [showReorderModal, setShowReorderModal] = useState(false)
  const [reorderOptions, setReorderOptions] = useState([])
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [orderPeriod, setOrderPeriod] = useState({
    startDate: null,
    endDate: null
  })
  const [summary, setSummary] = useState({
    total: 0,
    paid: 0,
    due: 0
  })

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams();
  let prevSearch = usePrevious(search) || ''

  useEffect(() => {

    if(search){
      prevSearch = search
      const queries = qs.parse(search.slice(1));

      applyFilter(queries)
      return
    }

    if(user.role === 'admin'){
      // setOrderPeriod({
      //   startDate: moment().subtract(2, 'months'),
      //   endDate: moment()
      // })
      setFilterPrompt(true)
      return
    }

    applyFilter()
  }, [])

  useEffect(() => {
    if(!search && user.role === 'admin'){
      setFilterPrompt(true)
      handleResetFilter()
      return
    }

    if(search !== prevSearch && !filterPrompt){
      const queries = qs.parse(search.slice(1));

      applyFilter(queries)
    }
  }, [params])

  useEffect(() => {
    const total = _.sumBy(items, order => parseFloat(order.current_total_price))
    const paid = _.reduce(items, (sum, order) => {
      const { manual_order, financial_status, current_total_price, partial_paid } = order

      if(financial_status === 'paid'){
        return sum + parseFloat(current_total_price)
      }

      if(manual_order && financial_status === 'partially_paid'){
        return sum + parseFloat(partial_paid)
      }

      return sum
    }, 0)
    const due = _.reduce(items, (sum, order) => {
      const { manual_order, financial_status, current_total_price, current_due } = order

      if(manual_order && financial_status === 'partially_paid'){
        return sum + parseFloat(current_due)
      }

      if(financial_status === 'unpaid'){
        return sum + parseFloat(current_total_price)
      }

      return sum
    }, 0)

    setSummary({ total, paid, due })
  }, [items])

  const applyFilter = (queries = {}) => {
    const {
      financial_status,
      fulfillment_status,
      order_period_start,
      order_period_end,
      email,
    } = queries

    setEmail(email)
    setFulfillmentStatus(fulfillment_status)
    setFinancialStatus(financial_status)
    setOrderPeriod({
      startDate: order_period_start ? moment(order_period_start, "D-M-YY") : null,
      endDate: order_period_end ? moment(order_period_end, "D-M-YY") : null
    })

    dispatch(listOrders(queries));
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2, "DD/MM/YYYY").unix() - moment(date1, "DD/MM/YYYY").unix()
      );
    } else {
      return (
        moment(date1, "DD/MM/YYYY").unix() - moment(date2, "DD/MM/YYYY").unix()
      );
    }
  };

  const renderOrderDetailLink = (cell, row) => {
    return (
      <Link
        key={row.id}
        to={{
          pathname: row.manual_order
            ? `/manual_orders/${row.id}`
            : `/orders/${row.id}`,
        }}
      >
        {row.name}
      </Link>
    );
  };

  const renderFulfillmentStatus = (cell, row) => {
    if (row.cancelled_at) {
      return (
        <Badge bg="danger text-uppercase">Cancelled</Badge>
      );
    } else if (row.fulfillment_status === "fulfilled") {
      return (
        <Badge bg="success text-uppercase">
          {row.fulfillment_status || "Unfulfilled"}
        </Badge>
      );
    } else if (row.fulfillment_status) {
      return (
        <Badge bg="warning text-uppercase">
          {row.fulfillment_status}
        </Badge>
      );
    } else {
      return (
        <Badge bg="warning text-uppercase">Unfulfilled</Badge>
      );
    }
  };

  const renderInvoiceDownload = (cell, row) => {

    const query = encodeURIComponent(`id=${row.id}&name=${row.name}`)

    return (
      <>
        { row.manual_order ? (
          cell &&
            <Link to={`/invoice?${query}`} target="_blank">
              <Button variant="secondary" className="btn-br-6 d-print-none">View</Button>
            </Link>
        ) : (
          <Link to={`/invoice?${query}`} target="_blank">
            <Button variant="secondary" className="btn-br-6 d-print-none">View</Button>
          </Link>
        )}
      </>
    )
  }

  const renderSalesReceipt = (cell, row) => {

    const query = encodeURIComponent(`id=${row.id}&name=${row.name}`)

    if(row.manual_order && cell){
      return (
        <Link to={`/receipt?${query}`} target="_blank">
          <Button variant="secondary" className="btn-br-6 d-print-none">View</Button>
        </Link>
      )
    }
  }

  const renderFinancialStatus = (cell, row) => {
    const { financial_status } = row
    if (_.lowerCase(financial_status) === "paid") {
      return (
        <Badge bg="success text-uppercase">{financial_status}</Badge>
      );
    } else if (
      _.lowerCase(financial_status) === "refunded" &&
      _.lowerCase(financial_status) === "partially refunded"
    ) {
      return (
        <Badge bg="danger text-uppercase">{financial_status}</Badge>
      );
    } else if (_.lowerCase(financial_status) === 'partially paid'){
      return (
        <Badge bg="warning text-uppercase">partial</Badge>
      );
    }

    return (
      <Badge bg="warning text-uppercase">{financial_status}</Badge>
    );
  };

  const renderItemsName = (cell, row) => {
    return <span key={`name_${row.id}`}>{row.line_item_name}</span>;
  };

  const renderPrice = (cell, row) => {
    if(cell){
      return <span>{currency} {parseFloat(cell).toFixed(2)}</span>
    }
  }

  const renderPaidAmount = (cell, row) => {
    if(row.financial_status === 'paid'){
      return <span>{currency} {parseFloat(row.current_total_price).toFixed(2)}</span>
    }

    return <span>{currency} {parseFloat(cell || 0).toFixed(2)}</span>
  }

  const renderDue = (cell, row) => {
    if(row.financial_status === 'paid'){
      return <span>{currency} 0.00</span>
    }

    if(row.financial_status === 'unpaid'){
      return <span>{currency} {row.current_total_price}</span>
    }

    const due = (parseFloat(row.current_total_price) - parseFloat(row.partial_paid || 0)).toFixed(2)

    return <span>{currency} {due}</span>
  }

  const priceSort = (price1, price2, order) => {
    if (!price1) {
      price1 = 0;
    }

    if (!price2) {
      price2 = 0;
    }

    if (order === "desc") {
      return (
        parseFloat(price2) - parseFloat(price1)
      );
    } else {
      return (
        parseFloat(price1) - parseFloat(price2)
      );
    }
  }

  const renderSelectFilter = (options, defaultValue, onChange) => {
    return (
      <Form.Select className="form-control" onChange={onChange} value={defaultValue || ''}>
        {Object.keys(options).map((key) => {
          return (
            <option key={key} value={key}>
              {options[key]}
            </option>
          );
        })}
      </Form.Select>
    );
  };

  const handleResetFilter = () => {
    setEmail('')
    setFulfillmentStatus('any')
    setFinancialStatus('any')
    setOrderPeriod({
      // startDate: isAdmin ? moment().subtract(2, 'months') : null,
      // endDate: isAdmin ? moment() : null,
      startDate: null,
      endDate: null,
    })
  }

  const handleOnClickFilter = () => {
    if(isAdmin && !email){
      dispatch(addError('Please insert an email'))
      return
    }

    setFilterPrompt(false)

    const { startDate: orderPeriodStart, endDate: orderPeriodEnd } = orderPeriod

    let orderPeriodStartShortFormat, orderPeriodEndShortFormat
    if (orderPeriodStart) {
      orderPeriodStartShortFormat = orderPeriodStart.format("D-M-YY");
    }

    if(orderPeriodEnd){
      orderPeriodEndShortFormat = orderPeriodEnd.format("D-M-YY");
    }

    const queryString = qs.stringify({
      financial_status: financialStatus,
      fulfillment_status: fulfillmentStatus,
      order_period_start: orderPeriodStartShortFormat,
      order_period_end: orderPeriodEndShortFormat,
      email: email
    });

    navigate(`${pathname}?${queryString}`, { replace: true });
  }

  const onClickReorder = async products => {
    if(products.length > 1) {
      setLoadingOverlay(true);
      let reorder_options = []

      return Promise.map(products, async function (product) {

        return api(`/product/handle/${product.product_id}`, {
          method: 'GET',
        }).then(res => {
          if(res.product_url && res.image_url) {
            reorder_options.push({...product, ...res})
          }
        })
      }, { concurrency: 1 }).then(() => {

        if(reorder_options.length > 0){
          setShowReorderModal(true)
          setReorderOptions(reorder_options)
        }

        setLoadingOverlay(false);
      }).catch(err => {
        console.log(err)
        setLoadingOverlay(false);
      })

    } else if (products.length === 1) {
      setLoadingOverlay(true);

      return api(`/product/handle/${products[0].product_id}`, {
        method: 'GET'
      }).then(res => {
        setLoadingOverlay(false);

        if(res.product_url) {
          window.open(res.product_url, '_blank');
        }
      }).catch(err => {
        console.log(err)
        setLoadingOverlay(false);
      })
    }
  }

  const renderReorderButton = (cell, row) => {
    const line_items = cell

    let products = []
    _.each(line_items, line_item => {
      const { product_id, name } = line_item

      if(product_id && name){
        products.push({ product_id, name })
      }
    })

    if(products.length > 0){
      return (
        <Button variant="secondary" className="btn-br-6 d-print-none" onClick={() => onClickReorder(products)}>Reorder</Button>
      )
    }
  }

  const ordersCol = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      sort: true,
      headerStyle: {
        resize: 'horizontal'
      }
    },
    {
      dataField: 'created_date',
      text: 'Date',
      sort: true,
      sortFunc: dateSort,
      headerStyle: {
        width: '120px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'id',
      text: 'Order Id',
      csvExport: false,
      sort: true,
      formatter: renderOrderDetailLink,
      headerStyle: {
        width: '120px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'receiver',
      text: 'Receiver',
      sort: true,
      headerStyle: {
        width: '170px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'fulfillment_status',
      text: 'Fulfillment',
      sort: true,
      formatter: renderFulfillmentStatus,
      headerStyle: {
        width: '110px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'line_item_name',
      text: 'Items',
      sort: true,
      formatter: renderItemsName,
      headerStyle: {
        width: '200px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'line_items',
      text: 'Reorder',
      formatter: renderReorderButton,
      headerStyle: {
        width: '120px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'financial_status',
      text: 'Paid',
      formatter: renderFinancialStatus,
      sort: true,
      headerStyle: {
        width: '80px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'current_total_price',
      text: 'Total',
      sort: true,
      sortFunc: priceSort,
      formatter: renderPrice,
      headerStyle: {
        width: '110px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'partial_paid',
      text: 'Paid Amount',
      sort: true,
      sortFunc: priceSort,
      formatter: renderPaidAmount,
      headerStyle: {
        width: '110px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'current_due',
      text: 'Due',
      sort: true,
      sortFunc: priceSort,
      formatter: renderDue,
      headerStyle: {
        width: '110px',
        resize: 'horizontal'
      }
    },
    {
      dataField: 'order_invoice',
      text: 'Invoice',
      formatter: renderInvoiceDownload,
      headerStyle: {
        width: '135px',
      },
    },
    {
      dataField: 'sales_receipt',
      text: 'Sales Receipt',
      formatter: renderSalesReceipt,
      headerStyle: {
        width: '135px',
      },
    },
  ];

  const options = {
    showTotal: true,
    sizePerPage: 10,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: 'All', value: items && items.length
    }],
  };

  return (
    <>
      {
        loadingOverlay &&
          <OverlayLoader />
      }
      <TabHeader
        title="My Orders"
        tab_id="orders"
      />
      <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey={isAdmin ? "0" : ''}>
        <Accordion.Item style={{borderRadius: '18px', border: 'none'}} className="mb-3" eventKey="0">
          <Accordion.Header><h5 style={{marginBottom: '0'}}>Filters</h5></Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-xs-12 col-sm-5 col-xl-3 mb-3 mb-sm-3">
                    <label style={{marginBottom: '5px'}}>Fullfillment</label>
                    {renderSelectFilter(
                      fulfillmentStatusOptions,
                        fulfillmentStatus,
                        e => setFulfillmentStatus(e.target.value)
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-5 col-xl-3 mb-3 mb-sm-3">
                    <label style={{marginBottom: '5px'}}>Financial Status</label>
                    {renderSelectFilter(
                      financialStatusOptions,
                        financialStatus,
                        e => setFinancialStatus(e.target.value)
                    )}
                  </div>
                  <div className="d-grid col-xs-12 col-sm-10 col-xl-5 mb-3 mb-sm-3">
                    <label style={{marginBottom: '5px'}}>Order Period</label>
                    <DateRangePicker
                      small
                      minimumNights={0}
                      numberOfMonths={1}
                      displayFormat="D/M/YY"
                      showClearDates={false}
                      showDefaultInputIcon={true}
                      isOutsideRange={() => false}
                      startDate={orderPeriod.startDate} // momentPropTypes.momentObj or null,
                      startDateId="order-period-start"
                      endDate={orderPeriod.endDate} // momentPropTypes.momentObj or null,
                      endDateId="order-period-end"
                      onDatesChange={({ startDate, endDate }) => {
                        setOrderPeriod({ startDate, endDate })
                      }
                      } // PropTypes.func.isRequired,
                      focusedInput={focusedOrderPeriod} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={
                        (focusedInput) => setFocusedOrderPeriod(focusedInput)
                      } // PropTypes.func.isRequired,
                    />
                  </div>
                </div>
              </div>

              <div className="filter-buttons col-sm-3 d-flex flex-sm-column flex-lg-row justify-content-between my-2 mb-auto">
                <Button
                  className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                  variant="secondary"
                  style={{width: '100%'}}
                  onClick={() => handleResetFilter()}
                >Reset
                </Button>
                <Button
                  className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                  variant="primary"
                  style={{width: '100%'}}
                  onClick={() => handleOnClickFilter()}
                >Apply
                </Button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
        {
          isAdmin &&
            <>
              <Col sm="auto" className="me-auto">
                <div className="d-flex flex-row align-items-end">
                  <div className="me-2">
                    <label>Customer Email</label>
                    <input type="text" className="form-control" id="orderId" name="orderId" placeholder={searchPlaceholder} value={email} onChange={e => setEmail(e.target.value)}/>
                  </div>
                  <div className="search-order-btn">
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => handleOnClickFilter()}
                    >
                      Search Email
                    </Button>
                  </div>
                </div>

              </Col>
            </>
        }
      </Container>
      {
        loading &&
          <Loader/>
      }
      {
        filterPrompt &&
          <div className="filter-prompt">
            <img src={promptIcon}></img>
            <h3 className="mb-0">Please select filter options</h3>
          </div>
      }
      {
        !filterPrompt && !loading && loaded && items &&
          <>
            {
              items.length > 0 &&
                <Card className="mb-3 curve-border shadow-sm">
                  <Card.Body style={{paddingLeft: '1.25rem', paddingRight: '1.25rem'}}>
                    <h5 className="mb-0">Summary</h5>
                    <Row style={{textAlign: 'center'}}>
                      <Col sm={3} className="mb-3">
                        <label>Orders</label><br/>
                        {items.length}
                      </Col>
                      <Col sm={3} className="mb-3">
                        <label>Total</label><br/>
                        {currency} {summary.total.toFixed(2)}<br/>
                      </Col>
                      <Col sm={3} className="mb-3">
                        <label>Paid</label><br/>
                        {currency} {summary.paid.toFixed(2)}<br/>
                      </Col>
                      <Col sm={3} className="mb-3">
                        <label>Due</label><br/>
                        {currency} {summary.due.toFixed(2)}<br/>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
            }
            <ToolkitProvider
              keyField='id'
              data={items}
              columns={ordersCol}
            >
              {
                props => (
                  <>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: 'created_date',
                          order: 'desc',
                      }]}
                      noDataIndication={() => 'There is no data to display'}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
      }
      {
        showReorderModal &&
          <Modal style={{top:'15%', border: 'none'}} show={true} onHide={() => setShowReorderModal(false)} size="lg">
            <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
              <Modal.Title>Select Product</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
              <div className="d-grid gap-4">
                {
                  _.map(reorderOptions, item => {
                    return (
                      <a style={{color: 'unset', textDecoration: 'none'}} href={item.product_url} target="_blank">
                        <div className='d-grid gap-1 reorder-option' style={{gridTemplateColumns: '250px auto'}}>
                          <div className="reorder-mobile" style={{textAlign: 'center'}}><img style={{maxWidth: '200px'}} src={item.image_url}/></div>
                          <p className="reorder-mobile">
                            {item.name}
                          </p>
                        </div>
                      </a>
                    )
                  })
                }
              </div>
            </Modal.Body>
          </Modal>
      }
    </>
  )
}

export default Orders;
