import { useState } from "react";
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ title, children, noBtnTitle = "No", yesBtnTitle = "Yes", onClickYes, onClose }) => {
  const [loading, setLoading] = useState(false)

  const handleOnClickYes = () => {
    setLoading(true)
    onClickYes();
  }

  const handleOnClose = () => {
    if (!loading) {
      onClose();
    }
  }

  return (
    <Modal style={{top:'15%', border: 'none'}} show={true} onHide={handleOnClose}>
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        {
          title &&
          <h4 className="mb-0">{title}</h4>
        }
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        {children}
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
        <Button variant="danger-secondary" onClick={handleOnClose} disabled={loading}>{noBtnTitle}</Button>
        {
          onClickYes &&
          <Button variant="primary" onClick={handleOnClickYes} disabled={loading}>
            {loading ? "Loading..." : yesBtnTitle}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
