import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import MobileDetect from 'mobile-detect';

import Dashboard from './Dashboard';
import Logout from './Logout';
import Users from './Users';
import OrderDetail from './OrderDetail';
import Orders from './Orders';
import Credits from './Credits';
import Invoice from './Invoice';
import Receipt from './Receipt';
import Catalogue from './Catalogue';
import Profile from './Profile';
import ManualOrderDetail from './ManualOrderDetail';
import RequestCredits from './RequestCredits';
import Rewards from './Rewards';
import Logs from './Logs';
import NotFound from '../components/NotFound';
import GFBAlert from '../components/GFBAlert';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Forbidden from '../components/Forbidden';

import { resetError } from '../redux/slices/error';
import { fadeTransition } from '../styles/transitions';
import { listQuickHelps } from '../redux/slices/quickHelp';

const AdminRoute = ({ user, children }) => {
  return (
    <>
      {
        user && user.role === 'admin' ? (
          children
        ) : (
          <Navigate to="/403" />
        )
      }
    </>
  )
}

const App = () => {
  const { user } = useSelector(state => state.auth);
  const { error, errorMsg } = useSelector(state => state.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!user){
      navigate('/login')
    }
  }, [user, navigate])

  useEffect(() => {
    dispatch(listQuickHelps());
  }, [])

  const handleOnClickEmail = () => {
    window.location.href = "mailto:marketplace@giftr.my"
  }

  const handleOnClickWhatsapp = () => {
    window.open("https://wa.me/60173591842", '_blank')
  }

  const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;

  return (
    <>
      <Transition in={error} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="danger" alert={errorMsg} handleClose={() => {
              if(error){
                dispatch(resetError())
              }
            }} />
        )}
      </Transition>
      <Header/>
      <div className="row">
        <div className="d-none d-md-block position-fixed sidebar">
          <Sidebar />
        </div>
        <div className="main">
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/orders" element={<Orders/>}/>
            <Route path="/orders/:orderId" element={<OrderDetail />} />
            <Route path="/manual_orders/:orderId" element={<ManualOrderDetail />} />
            <Route path="/credits" element={<Credits/>}/>
            <Route path="/request_credits" element={<RequestCredits/>}/>
            <Route path="/catalogue" element={<Catalogue/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/rewards" element={<Rewards/>}/>
            <Route path="/users" element={<AdminRoute user={user} children={<Users/>}/>}/>
            <Route path="/logs" element={<AdminRoute user={user} children={<Logs/>}/>}/>
            <Route path="/invoice" element={<Invoice/>}/>
            <Route path="/receipt" element={<Receipt/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/403" element={<Forbidden/>} />
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </div>
        <div>
        <Fab
          id="contact-button"
          icon={<FontAwesomeIcon icon={solid('comments')} transform="grow-2"/>}
          style={isMobile ? {
            bottom: 0,
            right: 0,
          } : {
            bottom: 24,
            right: 24,
          }}
          mainButtonStyles={{
            backgroundColor: '#81d8d0',
            transform: 'none',
          }}
          alwaysShowTitle
          text={`Chat With Your
Account Manager`}
        >
          <Action text="WhatsApp" style={{ backgroundColor: '#25D366'}} onClick={handleOnClickWhatsapp}>
            <FontAwesomeIcon icon={brands('whatsapp')} transform="grow-5"/>
          </Action>
          <Action text="Email" style={{ backgroundColor: '#00c3eb'}} onClick={handleOnClickEmail}>
            <FontAwesomeIcon icon={solid('envelope')} transform="grow-3"/>
          </Action>
        </Fab>
        </div>
      </div>
    </>
  )
}

export default App;
