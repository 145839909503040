import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import Loader from '../components/Loader';
import UserSelection from '../components/UserSelection';
import ConfirmModal from '../components/ConfirmModal';
import TabHeader from '../components/TabHeader';
import { clientDashboard, getDashbboard } from '../redux/slices/dashboard';
import { addError } from '../redux/slices/error';
import denominations from '../data/creditsDenom.json';
import creditsLogo from '../images/credits-logo.svg';
import fpxLogo from '../images/fpx-logo.svg';
import popularLogo from '../images/credits-popular.svg';
import api from '../helpers/apiClient';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'

const Dashboard = () => {
  const { item, loading, loaded } = useSelector(state => state.dashboard)
  const { user } = useSelector(state => state.auth)
  const [selectedUser, setSelectedUser] = useState("")
  const [selectedDenom, setSelectedDenom] = useState({ value: null, amount: '' })
  const [showConfirmPurchase, setShowConfirmPurchase] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if(user && user.role === 'client'){
      dispatch(clientDashboard())
    }
  }, [])

  const handleOnUserChange = (e) => {
    const { value } = e.target;

    setSelectedUser(value)
    dispatch(getDashbboard(value));
  }

  const savings = (name, value) => {
    return (
      <>
        {
          name === '1000' &&
            <img className='position-absolute' style={{top: '-10px', left: '-15px'}} height="45" src={popularLogo} />
        }
        {
          (name === '1000' || name === '2000') &&
            <div className='denom-savings d-inline-block'>Save RM{parseInt(name) - (value/100)}</div>
        }
      </>
    )
  }

  const purchaseCredits = () => {
    const { value, amount } = selectedDenom
    const { name, email } = item

    api('/purchase/credits', {
      method: 'POST',
      body: JSON.stringify({
        email,
        name,
        amount: value,
        reference_1_label: "Credits Amount",
        reference_1: `${amount}`,
        description: `GFB credits: ${currency}${amount}`,
        type: 'credits'
      })
    })
      .then(result => {
        window.location = result.url;
      })
  }

  return (
    <>
      <TabHeader
        title="Dashboard"
        tab_id="dashboard"
        children={
          <>
            {
              user && user.role === 'admin' &&
                  <UserSelection handleOnUserChange={handleOnUserChange} selectedUser={selectedUser} />
            }
          </>
        }
      />
      {
        loading &&
          <Loader/>
      }
      {
        !loading && item &&
          <div className='d-grid gap-4 label-strong' style={{marginBottom: '5rem'}}>
            <Row>
              <Col sm={6} className="mb-2">
                <Card className="curve-border shadow-sm p-2">
                  <Card.Body>
                    <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.name}</h4>
                    <p className="d-print-none my-3">{item.created_at ? `Customer for ${moment.duration(moment().diff(moment(item.created_at))).humanize()}` : ""}</p>
                    {
                      item.orders &&
                        <Row style={{textAlign: 'center'}}>
                          {
                            item.orders.length > 0 &&
                              <Col sm={6}>
                                <label>Last Order</label><br/>
                                <Link to={{pathname: `/${item.orders[0].manual_order ? 'manual_orders' : 'orders'}/${item.last_order_id}`, state: {modal: false}}}>
                                  {item.last_order_name}
                                </Link>
                              </Col>
                          }
                          <Col sm={6}>
                            <label>Total Spent</label><br/>
                            {currency} {item.total_spent.toFixed(2)}<br/>
                            {item.orders_count} order{item.orders_count > 1 ? 's' : ''}
                          </Col>
                        </Row>
                    }
                  </Card.Body>
                </Card>
              </Col>
              <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
              <Col>
                <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                  <Card.Header className="tealCardHeader">My Info</Card.Header>
                  <Card.Body className="grey-label p-4">
                      {
                        item.email &&
                          <Col lg={6} className="d-grid mb-2">
                            <label>Email</label>
                            <span>{item.email}</span>
                          </Col>
                      }
                      {
                        item.phone_number &&
                          <Col lg={6} className="d-grid mb-2">
                            <label>Phone</label>
                            <span>{item.phone_number}</span>
                          </Col>
                      }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Card className="curve-border shadow-sm mb-3">
              <Card.Body>
                <h3 className="p-2">Purchase Giftr Credits</h3>
                <div>
                  <Row>
                    <Col xs={12} sm={4} xl={2} className="pb-2 text-center">
                      <img src={creditsLogo} height="150" style={{maxWidth: '100%'}} className="float-sm-end"/>
                    </Col>
                    <Col xs={12} sm={8} xl={10} className="pb-2">
                      <h5 style={{textDecoration: 'underline'}}>What is Giftr Credits?</h5>
                      <ul>
                        <li>Suitable for frequent repeated purchases for business, just apply the code during checkout to place order on giftr.my</li>
                        <li>Each code can be used for multiple transactions.</li>
                        <li>Valid for 2 years. Unused balance is not refundable.</li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} className="px-3 py-2">
                      <Row>
                        {
                          denominations.map(({ value, name, key }) => {
                            const size = (name === '1000' || name === '2000') ? 6 : 3

                            return (
                              <Col key={key} className='my-1' sm={size}>
                                <Button variant="light" className={`${selectedDenom.value === value ? 'denom-active': ''} w-100 position-relative`} style={{border: '1px solid #dfdfdf'}} onClick={() => {
                                  setSelectedDenom({
                                    value,
                                    amount: name
                                  })
                                }}>
                                  <span className="me-2 d-inline-block">{currency}{name}</span>{savings(name, value)}
                                </Button>
                              </Col>
                            )
                          })
                        }
                      </Row>
                    </Col>
                    <Col sm={3} className="py-2 px-3 text-center" style={{fontSize: '1.2rem'}}>
                      { selectedDenom &&
                        <p>Total - {`RM${(selectedDenom.value/100).toFixed(2)}`}</p>
                      }
                      <Button className="btn-cta w-100" style={{border: '1px solid #dfdfdf', fontSize: '1.2rem'}}
                        onClick={() => {
                          if(!selectedDenom.value && !selectedDenom.amount){
                            dispatch(addError('Please select a denomination'))
                            return
                          }
                          setShowConfirmPurchase(true)
                        }}
                      >
                        Buy Now
                        <img src={fpxLogo} width="60" style={{margin: '0 5px'}}/>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
      }
      {
        showConfirmPurchase &&
          <ConfirmModal
            title="Confirm Purchase"
            noBtnTitle="Cancel"
            yesBtnTitle="Confirm"
            onClickYes={() => purchaseCredits()}
            onClose={() => setShowConfirmPurchase(false)}
          >
            <p className="mb-0">Confirm purchase of {currency}{selectedDenom.amount} credits?</p>
            <p className='mb-0'>Total: {
              parseInt(selectedDenom.amount) > (selectedDenom.value/100) ?
                <>
                  <del>{currency}{selectedDenom.amount}</del>
                  {" "}
                </>
                :
                ''
            }{currency}{(selectedDenom.value/100)}</p>
          </ConfirmModal>
      }
    </>
  )
}

export default Dashboard;
