import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Badge, Card } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import Loader from '../components/Loader';
import LineItems from '../components/LineItems';
import Fulfillment from '../components/Fulfillment';
import TabHeader from '../components/TabHeader';

import { fetchOrderDetail } from '../redux/slices/orderDetail';

const currency = window.location.hostname === 'business.giftr.my' ? 'RM' : 'S$'
const shopifyAdminDomain = window.location.hostname === 'seller.giftr.my' ? 'https://helpr-asia.myshopify.com/admin' : 'https://giftr-singapore.myshopify.com/admin'

const OrderDetail = () => {
  const { item, loading, loaded } = useSelector(state => state.orderDetail);
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  useEffect(() => {
    dispatch(fetchOrderDetail(orderId));

  }, [])

  useEffect(() => {
    console.log(item);

  }, [item])

  return (
    <>
      <TabHeader
        title="Order Detail"
        tab_id="order_detail"
      />
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded && item &&
          <>
            <div className='d-grid gap-4'>
              <div className="d-none d-print-block">⚠️ KINDLY DO NOT PASS THIS ORDER SLIP TO RECEIVER</div>
              <Row>
                <Col sm={6} md={5} lg={4}>
                  <Card className="order-detail-card shadow-sm p-2">
                    <Card.Body>
                      <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.name}</h4>
                      <p className="d-print-none my-3">placed on {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                      {
                        item.cancelled_at &&
                          <Badge bg="danger" style={{lineHeight: '1.5em'}}>CANCELLED</Badge>
                      }
                      <div className="d-grid gap-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr)'}}>
                        {
                          !item.cancelled_at && user.role === "admin" &&
                            <Button variant="secondary" className="btn-br-6 d-print-none" onClick={() => this.handleOnClickPrintInvoice(item.id)}>Print Invoice</Button>
                        }
                        {
                          (user.role === "admin" || user.role === 'staff') &&
                            <a href={`${shopifyAdminDomain}/orders/${item.id}`} target="_blank" className="btn-br-6 d-print-none btn btn-secondary btn-br-6">View in Shopify</a>
                        }
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
                <Col>
                  <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                    <Card.Header className="tealCardHeader">Order Info</Card.Header>
                    <Card.Body className="grey-label">
                      <Row className="flex-row">
                        <Col lg={4} className="m-2">
                          <Row className="flex-lg-column w-100">
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Financial Status</label>
                              <span className="text-uppercase">{item.financial_status}</span>
                            </Col>
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Fulfillment Status</label>
                              {
                                item.fulfillment_status ?
                                  <span className="text-uppercase" style={{color: '#4e8767'}}>{item.fulfillment_status}</span>
                                  :
                                  <span className="text-uppercase" style={{color: '#ffc107'}}>Unfulfilled</span>
                              }
                            </Col>
                            {
                              item.cancelled_at &&
                                <Col xs={6} lg={12} className="d-grid mb-2">
                                  <label>Order Status</label>
                                  <span  className="text-uppercase" style={{color: '#d15d5d'}}>Cancelled</span>
                                </Col>
                            }
                            {
                              item.note &&
                                <Col  xs={6} lg={12} className="d-grid mb-2">
                                  <label>Note</label>
                                  <span style={{color: '#d15d5d'}}>{item.note}</span>
                                </Col>
                            }
                          </Row>
                        </Col>
                        <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
                        <Col className="m-2 mt-2 my-lg-auto label-strong">
                          <Row>
                            <Col className="text-end"><label>Total Item Price</label></Col>
                            <Col>{currency} {item.current_subtotal_price}</Col>
                          </Row>
                          {
                            item.total_discounts !== '0.00' &&
                              <Row>
                                <Col className='text-end'><label>Discounts</label></Col>
                                <Col>-{currency} {item.total_discounts} ({item.discount_codes[0].code})</Col>
                              </Row>
                          }
                          <Row>
                            <Col className='text-end'><label>Subtotal</label></Col>
                            <Col>{currency} {item.current_subtotal_price}</Col>
                          </Row>
                          {
                            item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].price !== '0.00' &&
                              <Row>
                                <Col className='text-end'><label>Shipping Subtotal</label></Col>
                                <Col>{currency} {item.shipping_lines[0].price}</Col>
                              </Row>
                          }
                          {
                            item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].breakdown &&
                              item.shipping_lines[0].breakdown.map(breakdown => {
                                return breakdown &&
                                  <Row key={breakdown.vendor} className="shipping-lines">
                                    <Col className='text-end'>Shipping Fee({breakdown.vendor})</Col>
                                    <Col>{currency} { parseFloat(breakdown.shipping_fee).toFixed(2)}</Col>
                                  </Row>
                              })
                          }
                          <Row>
                            <Col className='text-end'><label>Total</label></Col>
                            <Col>{currency} {item.current_total_price}</Col>
                          </Row>
                          {
                            item.total_item_adjustment && item.total_item_adjustment !== '0.00' &&
                              <Row>
                                <hr/>
                                <Col className='text-end'><label>Item Price Adjustment</label></Col>
                                <Col>{currency} {item.total_item_adjustment}</Col>
                              </Row>
                          }
                          {
                            item.total_shipping_adjustment && item.total_shipping_adjustment !== '0.00' &&
                              <Row>
                                <Col className='text-end'><label>Shipping Adjustment</label></Col>
                                <Col>{currency} {item.total_shipping_adjustment}</Col>
                              </Row>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="overflowX-auto">
                <Col>
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                    <Card.Body className="mx-2">
                      <Row>
                        {
                          item.customer && user && user.role !== 'supplier' &&
                            <Col lg={2} className="d-print-none grey-label">
                              <div className="d-grid mb-2">
                                <label>Customer Name</label>
                                <span>{item.customer.first_name} {item.customer.last_name}</span>
                              </div>
                              <div className="d-grid mb-2">
                                <label>Email</label>
                                <span style={{wordBreak: 'break-all'}}>{item.customer.email}</span>
                              </div>
                              {
                                item.customer.note &&
                                  <div className="d-grid mb-2">
                                    <label>Note</label>
                                    <span style={{color: '#d15d5d'}}>{item.customer.note}</span>
                                  </div>
                              }
                              {
                                (item.note_attributes && item.note_attributes.length && _.find(item.note_attributes, n => n.name === "Sender's phone number")) &&
                                  <div className="d-grid mb-2">
                                    <label>Sender's phone number</label>
                                    <span>{_.find(item.note_attributes, n => n.name === "Sender's phone number").value}</span>
                                  </div>
                              }
                            </Col>
                        }
                        {
                          (item.note || (item.note_attributes && item.note_attributes.length)) || item.shipping_address ?
                            <Col lg={6} className="mt-2 mt-lg-0">
                              <Card className="greyCard">
                                <Card.Header className="greyCardHeader">
                                  Delivery Info
                                </Card.Header>
                                <Card.Body className="label-strong d-grid gap-2 overflowX-auto">
                                  {
                                    item.note_attributes.map(attribute => {

                                      if(attribute.name === 'remark'){
                                        return (
                                          <Row key={attribute.name}>
                                            <label className="text-uppercase">Internal {attribute.name}</label>
                                            <span className="delivery-info-value" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> {attribute.value.substring(attribute.value.indexOf(":") ? attribute.value.indexOf(":") + 1 : 0)}</span>
                                          </Row>
                                        )
                                      } else if(!_.startsWith(attribute.name, 'deliverydate') && !_.startsWith(attribute.name, 'ready_to_ship_') && !_.startsWith(attribute.name, 'proof_of_delivery_') && !_.startsWith(attribute.name, "Sender's phone number")){
                                        return (
                                          <Row className="d-print-none" key={attribute.name}>
                                            <label className="text-uppercase">{attribute.name}</label>
                                            <span className="delivery-info-value">{attribute.value.substring(attribute.value.indexOf(":") ? attribute.value.indexOf(":") + 1 : 0)}</span>
                                          </Row>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    item.shipping_address &&
                                      <>
                                        <Row>
                                          <label>Receiver</label>
                                          <span className="delivery-info-value">{item.shipping_address.name}</span>
                                        </Row>
                                        <Row>
                                          <div className="mt-2">
                                            <label>Delivery Address</label>
                                          </div>
                                          <span className="delivery-info-value">
                                            <address>
                                              {item.shipping_address.company}{item.shipping_address.company && <br />}
                                              {item.shipping_address.address1}<br />
                                              {item.shipping_address.address2}{item.shipping_address.address2 && <br />}
                                              {item.shipping_address.zip} {item.shipping_address.city}<br />
                                              {item.shipping_address.province}<br />
                                              {item.shipping_address.country}<br />
                                            </address>
                                          </span>
                                        </Row>
                                        <Row>
                                          <label>Contact</label>
                                          <span>{item.shipping_address.phone}</span>
                                        </Row>
                                      </>
                                  }
                                  {
                                    item.note &&
                                      <Row>
                                        <label>Note</label>
                                        <span className="delivery-info-value">{item.note}</span>
                                      </Row>
                                  }
                                </Card.Body>
                              </Card>
                            </Col>
                            : null
                        }
                        {
                          item.billing_address && user && user.role !== 'supplier' &&
                            <Col lg={4} className="d-print-none mt-3 mt-lg-0">
                              <Card className="greyCard label-strong">
                                <Card.Header className="greyCardHeader">Billing Address</Card.Header>
                                <Card.Body>
                                  <address>
                                    <label>{item.billing_address.name}</label><br />
                                    {item.billing_address.company}{item.billing_address.company && <br />}
                                    {item.billing_address.address1}<br />
                                    {item.billing_address.address2}{item.billing_address.address2 && <br />}
                                    {item.billing_address.zip} {item.billing_address.city}<br />
                                    {item.billing_address.province}<br />
                                    {item.billing_address.country}<br />
                                    {item.billing_address.phone}
                                  </address>
                                </Card.Body>
                              </Card>
                            </Col>
                        }
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="overflowX-auto">
                <Col>
                  <Card className="tealCard shadow-sm order-detail-item">
                    <Card.Header className="tealCardHeader">Items</Card.Header>
                    <Card.Body className="mx-2 my-1">
                      {
                        item.unfulfilled_items && item.unfulfilled_items.length ?
                          <>
                            <div className="mb-2">
                              <h4 className="d-inline-block mt-2" style={{color: '#FFC107'}}>UNFULFILLED</h4>
                            </div>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <LineItems orderId={item.id} noteAttributes={item.note_attributes} fulfillmentStatus={item.fulfillment_status} user={user} items={item.unfulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}} cancelledAt={item.cancelled_at} />
                            </div>
                          </>
                          :
                          null
                      }
                      {
                        item.fulfilled_items && item.fulfilled_items.length ?
                          <>
                            <Row className="mb-2">
                              <Col lg={3}>
                                <h4 style={{color: '#4e8767'}}>FULFILLED</h4>
                              </Col>
                            </Row>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <LineItems orderId={item.id} noteAttributes={item.note_attributes} displaySection={"fulfilled"} user={user} fulfillmentStatus={item.fulfillment_status} items={item.fulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}}/>
                            </div>
                          </>
                          :
                          null
                      }
                      {
                        item.cancelled_items && item.cancelled_items.length ?
                          <>
                            <Row className="mb-2">
                              <Col lg={3}>
                                <h4 style={{color: '#d15d5d'}}>CANCELLED</h4>
                              </Col>
                            </Row>
                            <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                              <LineItems orderId={item.id} noteAttributes={item.note_attributes} displaySection={"cancelled"} fulfillmentStatus={item.fulfillment_status} items={item.cancelled_items} style={{width: '100%', whiteSpace: 'nowrap'}} />
                            </div>
                          </>
                          :
                          null
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {
                item.fulfillments && item.fulfillments.length ?
                  <Row className="overflowX-auto">
                    <Col>
                      <Card className="d-print-none tealCard shadow-sm fulfillments">
                        <Card.Header className="tealCardHeader">Fulfillments</Card.Header>
                        <Card.Body className="mx-2 my-0 py-0">
                          {
                            item.fulfillments.map((fulfillment, i) => {
                              return (
                                <Fulfillment
                                  key={fulfillment.id}
                                  fulfillment={fulfillment}
                                  order={item}
                                  index={i}
                                />

                              )
                            })
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  :
                  null
              }
            </div>
          </>
      }
    </>
  )
}

export default OrderDetail;
